<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" v-if="!pageLoading" class="pl-1 pr-1">
          <profileHeader />

          <div style="margin-top: -20px">
            <p class="white--text pb-3">
              <br />
              <strong> About Us</strong> <br />
              <br />
              Welcome to Addis Ababa Jobs, the platform that connects employers
              with top talent effortlessly. <br />
              Our goal is to streamline the hiring process, making it easier for
              companies to post job opportunities and for job seekers to find
              their next career move. Whether you're looking to fill a position
              quickly or explore new job listings, we provide a simple,
              efficient solution to match the right people with the right roles.
              <br />
              <br />
              <br />

              <strong>Contact Us</strong> <br />
              <br />
              Email: <a>contact@jobsaddisababa.com </a><br />
              Phone: <a> +251918440700</a> <br />
              Telegram: <a>@addisababajobs_customerservice</a> <br /><br />

              <a href="https://t.me/W3w22121">@Developers</a> <br />

              <br />
              Join us and take the next step in building your team or advancing
              your career.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
  }),

  methods: {},
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}

#userAvt {
  background-color: #e96910;
}
</style>
