<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-if="$store.state.user != ''" style="font-size: 15px">
    <div class="text-center mt-15" v-if="pageLoading">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>

    <div id="homePage" class="pl-0 pr-1" v-if="!pageLoading">
      <profileHeader />
      <!-- <v-layout
            row
            wrap
            class="pl-3 pb-10"
            style="
              padding-top: 5px;
              background-image: linear-gradient(#4f3606, #110f1a);
              box-shadow: 0px -20px 0px #4f3606;
              margin-left: -18px;
              margin-right: -15px;
            "
          >
            <v-flex xs1 class="text-right">
              <a>
                <v-avatar size="40px" id="userAvt">
              
                  <v-icon class="white--text">person</v-icon>
                </v-avatar>
              </a>
            </v-flex>

            <v-flex xs5 class="mt-3 red--text pl-4">
              <h5
                v-if="$store.state.user.first_name != ''"
                class="white--text text-capitalize"
              >
                {{
                  $store.state.user.first_name +
                  " " +
                  $store.state.user.middle_name
                }}
              </h5>

              <h5 v-else>Profile <a class="ml-1">Edit</a></h5>
            </v-flex>

            <v-flex xs6 class="mt-3 text-right pr-4">
              <a
                @click="gotoTg"
                style="text-decoration: none"
                class="pink--text"
              >
                <h5 v-if="$store.state.language == 'English'">
                  Back to Telegram Channel
                </h5>
                <h5 v-else>ወደ ቴሌግራም ቻናል ይመለሱ</h5>
              </a>
            </v-flex>
          </v-layout> -->

      <v-layout row wrap class="pl-1 pr-1" style="margin-top: -25px">
        <v-flex xs12 class="mb-4 pl-10 pr-10">
          <a
            @click="
              postJobDialog = true;
              tgid = $store.state.user.tg_id;
              education = [];
            "
          >
            <v-card
              style="
                background-image: linear-gradient(#31ce5c, #242623);
                border-radius: 10px;
              "
              class="white--text text-center pa-1"
            >
              <h4>
                {{
                  $store.state.language == "English" ? "Post a Job" : "ሥራ ይለጥፉ"
                }}
              </h4>
            </v-card>
          </a>
        </v-flex>
        <v-flex xs6 md4>
          <a
            @click="
              $store.dispatch('changeBtn', 'jobs');
              $router
                .push({
                  name: 'myjobs',
                })
                .catch((err) => {});
            "
          >
            <v-card
              class="white--text ma-1 mb-2 text-center pt-3 pb-5"
              height="230px"
              style="
                background-image: linear-gradient(#292732, #292732);
                border-radius: 10px;
              "
            >
              <v-icon style="font-size: 50px" x-large class="blue--text">
                work
              </v-icon>
              <h4 class="pt-1 mb-4">My Job Posts</h4>

              <span>All Posted Jobs: {{ allPosts.length }}</span
              ><br />
              <span class="green--text">Active Jobs: {{ activeJobs }}</span
              ><br />

              <span>Pending jobs: {{ pendingJobs }}</span
              ><br />
              <span class="pink--text">Expired Jobs: {{ expiredJobs }}</span
              ><br />
              <span class="pink--text">Rejected Jobs: {{ rejectedJobs }}</span
              ><br />
            </v-card>
          </a>
        </v-flex>

        <v-flex xs6 md4>
          <a
            @click="
              $store.dispatch('changeBtn', '');
              $router
                .push({
                  name: 'myApplication',
                })
                .catch((err) => {});
            "
          >
            <v-card
              class="white--text ma-1 mb-2 text-center pt-3 pb-5"
              height="230px"
              style="
                background-image: linear-gradient(#292732, #292732);
                border-radius: 10px;
              "
            >
              <v-icon style="font-size: 50px" x-large class="blue--text"
                >desktop_mac
              </v-icon>

              <h4 class="pt-1 mb-4">My Applications</h4>

              <span>Total Applied: {{ allApplications.length }}</span
              ><br />

              <span
                >Pending:&nbsp; &nbsp;
                {{
                  allApplications.length -
                  acceptedApplications -
                  rejectedApplications
                }}</span
              ><br />
              <span class="green--text"
                >Accepted:&nbsp; &nbsp;{{ acceptedApplications }}</span
              ><br />

              <span class="pink--text"
                >Rejected:&nbsp; &nbsp; {{ rejectedApplications }}</span
              ><br />

              <h6
                class="orange--text text--darken-2 mt-3"
                style="font-size: 11px"
              >
                NB: Only for Employees
              </h6>
            </v-card>
          </a>
        </v-flex>

        <v-flex xs6 md4>
          <a>
            <v-card
              class="white--text ma-1 mb-2 text-center pt-5 pb-5"
              height="230px"
              style="
                background-image: linear-gradient(#292732, #292732);
                border-radius: 10px;
              "
            >
              <v-icon style="font-size: 50px" x-large class="green--text">
                group
              </v-icon>
              <h4 class="pt-1">Active Users</h4>
              <br />
              <h5 class="mb-1">{{ "265,987" }}</h5>
              <br />
              <br />

              <v-divider id="divider"></v-divider>
              <div class="pt-2">
                <v-layout row wrap class="pt-3 pl-2">
                  <v-flex xs4>
                    <v-btn
                      @click="shareChannel"
                      x-small
                      left
                      text
                      style="font-size: 18px"
                      class="text-capitalize pr-5"
                    >
                      <v-icon small class="grey--text text--lighten-1 pt-1 pb-1"
                        >share</v-icon
                      ></v-btn
                    >
                  </v-flex>

                  <v-flex xs8>
                    <v-btn
                      href="https://t.me/W3w22121"
                      text
                      x-small
                      style="font-size: 10px"
                      class="orange--text text--darken-2 text-capitalize"
                      >Comment</v-btn
                    >
                  </v-flex>
                </v-layout>
              </div>
            </v-card>
          </a>
        </v-flex>
        <v-flex xs6 md4>
          <a>
            <v-card
              class="white--text ma-1 mb-2 text-center pt-5 pb-5"
              height="230px"
              style="
                background-image: linear-gradient(#292732, #292732);
                border-radius: 10px;
              "
            >
              <v-icon
                style="font-size: 50px"
                x-large
                class="orange--text text--darken-3"
              >
                help
              </v-icon>

              <h4 class="pt-1">Support 24/7</h4>
              <br />
              <h5 class="mb-1">Contact Us</h5>

              <span
                >Call
                <a @click="copyNumber(contactPhone)">{{
                  contactPhone
                }}</a></span
              >
              <span
                style="font-size: 11px"
                v-if="contactCopyMessage != ''"
                class="green--text"
              >
                <br />
                {{ contactCopyMessage }}
              </span>
              <br />
              <br v-if="contactCopyMessage == ''" />

              <v-divider id="divider"></v-divider>
              <div class="pt-2">
                <v-layout row wrap class="pt-3 pr-2 pl-2">
                  <v-flex xs4 class="text-center">
                    <v-btn
                      text
                      x-small
                      href="https://t.me/W3w22121"
                      target="_blank"
                      ><img
                        height="25"
                        width="25"
                        contain
                        src="../assets/tg.png"
                      />
                    </v-btn>
                  </v-flex>

                  <v-flex xs4 class="text-center">
                    <v-btn
                      text
                      x-small
                      href="https://t.me/W3w22121"
                      target="_blank"
                      ><img
                        height="18"
                        width="23"
                        contain
                        src="../assets/youtube2.png"
                      />
                    </v-btn>
                  </v-flex>

                  <v-flex xs4 class="text-center">
                    <v-btn
                      text
                      x-small
                      href="https://t.me/W3w22121"
                      target="_blank"
                      ><img
                        height="18"
                        width="23"
                        contain
                        src="../assets/tiktok.png"
                      />
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-card>
          </a>
        </v-flex>
      </v-layout>
    </div>

    <v-dialog
      transition="dialog-bottom-transition"
      v-model="postJobDialog"
      fullscreen
      persistent
    >
      <v-card style="background-color: #110f1a" class="white--text">
        <v-card-title>
          {{ $store.state.language == "English" ? "Post a Job" : "ሥራ ይለጥፉ" }}
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            dark
            color="pink"
            text
            @click="postJobDialog = false"
          >
            {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-15">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap class="pa-0 mt-1 pr-1 pl-1">
              <v-flex xs12 class="text-center white--text ma-1">
                <h4>
                  {{
                    $store.state.language == "English"
                      ? " Please read carefully and complete all the required fields."
                      : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያጠናቅቁ።"
                  }}
                </h4>
              </v-flex>
              <v-flex xs12 md5 class="mt-2">
                <!-- <span class="white--text ml-2">{{
                  $store.state.language == "English"
                    ? "Telegram Id"
                    : "የቴሌግራም ቁጥር"
                }}</span>

                <input
                  required
                  :rules="[(v) => !!v || 'title is required']"
                  v-model="tgid"
                  class="pa-2 pl-3 mb-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Telegram Id'
                      : 'የቴሌግራም ቁጥር'
                  "
                />
                <br /> -->
                <span class="white--text"
                  >{{
                    $store.state.language == "English"
                      ? "Select a language to view your post on our Telegram channel in that language:"
                      : "ቋንቋ ይምረጡ፤ ልጥፍዎን በመረጡት ቋንቋ ቴሌግራም ቻናላችን ላይ ይታያል:"
                  }}
                  <br
                /></span>
                <div
                  class="pl-3 mt-2 mb-2 text-center"
                  style="
                    font-size: 18px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                    padding-top: 1px;
                  "
                >
                  <v-radio-group
                    v-model="language"
                    row
                    class="white--text text-center"
                  >
                    <v-radio
                      @click="$store.dispatch('changeLanguage', 'English')"
                      base-color="white"
                      value="English"
                      color="white"
                      style="border-color: white; color: white"
                      class="custom-radio"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "English"
                            : "English"
                        }}</span>
                      </template>
                    </v-radio>
                    <v-radio
                      value="አማርኛ"
                      color="white"
                      @click="$store.dispatch('changeLanguage', 'አማርኛ')"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Amharic"
                            : "አማርኛ"
                        }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>

                <input
                  required
                  :rules="[(v) => !!v || 'Company Name is required']"
                  v-model="companyName"
                  class="pa-2 pl-3"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Company Name*'
                      : 'የኩባንያ ስም*'
                  "
                />
                <br />

                <input
                  :rules="[(v) => !!v || 'Location is required']"
                  required
                  v-model="address"
                  class="pa-2 pl-2 mt-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Work Location*'
                      : 'የስራ ቦታ*'
                  "
                />
                <br />

                <div v-for="index in postCounter" :key="index">
                  <input
                    required
                    :rules="[(v) => !!v || 'title is required']"
                    v-model="title[index - 1]"
                    class="pa-2 pl-3 mt-6"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Job Title*'
                        : 'የስራ መጠሪያ*'
                    "
                  />

                  <div
                    class="pl-3 mt-2"
                    style="
                      font-size: 18px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                      padding-top: 2px;
                    "
                  >
                    <v-radio-group
                      v-model="sex[index - 1]"
                      row
                      class="white--text"
                    >
                      <br />
                      <Span
                        ><strong>{{
                          $store.state.language == "English" ? "Sex:" : "ጾታ"
                        }}</strong></Span
                      >
                      &nbsp;&nbsp;&nbsp;
                      <v-radio
                        base-color="white"
                        value="Male"
                        color="white"
                        style="border-color: white; color: white"
                        class="custom-radio"
                      >
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English" ? "Male" : "ወንድ"
                          }}</span>
                        </template>
                      </v-radio>
                      <v-radio value="Female" color="white">
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English" ? "Female" : "ሴት"
                          }}</span>
                        </template>
                      </v-radio>

                      <v-radio value="Both" color="white">
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English" ? "Both" : "ሁለቱም"
                          }}</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <input
                    required
                    v-model="vacancies[index - 1]"
                    class="pa-2 pl-3 mt-2"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'How many employees required (Opt)'
                        : 'ምን ያህል ሰራተኞች ያስፈልጋሉ'
                    "
                  />

                  <input
                    required
                    v-model="salary[index - 1]"
                    class="pa-2 pl-3 mt-2"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Salary (Optional)'
                        : 'ደሞዝ'
                    "
                  />

                  <input
                    required
                    v-model="experience[index - 1]"
                    class="pa-2 pl-3 mt-2"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Experience Level (Optional)'
                        : 'የስራ ልምድ'
                    "
                  />

                  <v-flex xs12>
                    <v-menu
                      max-width="100vw"
                      :close-on-content-click="true"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <input
                          v-bind="attrs"
                          v-on="on"
                          @focus="unfocus"
                          required
                          v-model="education[index - 1]"
                          class="pa-2 pl-3 mt-2"
                          style="
                            font-size: 16px;
                            border-radius: 5px;
                            color: white;
                            background-color: #292732;
                          "
                          type="text"
                          :placeholder="
                            $store.state.language == 'English'
                              ? 'Education Qualification (Optional)'
                              : 'የትምህርት ደረጃ'
                          "
                        />
                      </template>
                      <v-card
                        style="width: 92vw; max-height: 100vh; overflow-y: auto"
                      >
                        <v-list :lines="false" density="compact">
                          <v-list-item
                            style="border-bottom: 1px solid grey"
                            v-for="item in educationLists"
                            :key="item"
                            :value="item"
                            color="primary"
                            @click="
                              education[index - 1] = item;
                              education.splice(index - 1, 1, item);
                            "
                          >
                            <v-list-item-title
                              class="pl-5"
                              v-text="item"
                            ></v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-flex>

                  <input
                    v-if="
                      education[index - 1] != undefined &&
                      education[index - 1] != '' &&
                      education[index - 1] != 'Not Required'
                    "
                    required
                    v-model="fieldStudy[index - 1]"
                    class="pa-2 pl-3 mt-2"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Field of Study or Skill (Optional)'
                        : 'የጥናት መስክ ወይም ችሎታ'
                    "
                  />
                  <v-flex xs12>
                    <v-menu
                      max-width="100vw"
                      :close-on-content-click="true"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <input
                          v-bind="attrs"
                          v-on="on"
                          @focus="unfocus"
                          required
                          v-model="jobType[index - 1]"
                          class="pa-2 pl-3 mt-2"
                          style="
                            font-size: 16px;
                            border-radius: 5px;
                            color: white;
                            background-color: #292732;
                          "
                          type="text"
                          :placeholder="
                            $store.state.language == 'English'
                              ? 'Job Type (Optional)'
                              : 'የቅጥር አይነት'
                          "
                        />
                      </template>
                      <v-card
                        style="width: 92vw; max-height: 100vh; overflow-y: auto"
                      >
                        <v-list density="compact">
                          <v-list-item
                            style="border-bottom: 1px solid grey"
                            @click="jobType[index - 1] = item"
                            v-for="(item, k) in jobTypLists"
                            :key="k"
                            :value="item"
                            color="primary"
                          >
                            <v-list-item-title
                              class="pl-5"
                              v-text="item"
                            ></v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-flex>
                </div>

                <div class="text-right pt-3">
                  <v-btn
                    right
                    @click="postCounter = postCounter + 1"
                    text
                    small
                    class="text-capitalize blue--text"
                  >
                    {{
                      $store.state.language == "English"
                        ? "Add More"
                        : "ስራ ይጨምሩ"
                    }}
                    <v-icon class="blue--text ml-2 mr-2">add_circle</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="postCounter > 1"
                    @click="
                      education.splice(postCounter - 1, 1);
                      title.splice(postCounter - 1, 1);
                      sex.splice(postCounter - 1, 1);
                      vacancies.splice(postCounter - 1, 1);
                      experience.splice(postCounter - 1, 1);
                      fieldStudy.splice(postCounter - 1, 1);
                      jobType.splice(postCounter - 1, 1);
                      salary.splice(postCounter - 1, 1);
                      postCounter = postCounter - 1;
                    "
                    text
                    small
                    class="text-capitalize ml-1 blue--text text-right"
                  >
                    <v-icon class="pink--text ml-2">cancel</v-icon>
                  </v-btn>
                </div>

                <textarea
                  :rules="[(v) => !!v || 'Description is required']"
                  required
                  class="pa-2 pl-3 mt-6"
                  v-model="detailDescription"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Detail Description*'
                      : 'ዝርዝር መግለጫ*'
                  "
                  rows="5"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    background-color: #292732;
                  "
                ></textarea>

                <v-card
                  style="background-color: #292732"
                  elevation="0"
                  light
                  @dragend="OnDragEnter"
                  @dragleave="OnDragLeave"
                  @dragover.prevent
                  @drop="OnDrop"
                  class="text-center"
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref="selectImage"
                    style="display: none"
                    @change="OnInputFile"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref="changeImage"
                    style="display: none"
                    @change="OnInputFile"
                  />

                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-0 mr-0"
                    :src="image1"
                    height="142"
                    contain
                    v-if="image1 != ''"
                    @click="changeImage()"
                  ></v-img>

                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-5 mr-5 text-center"
                    v-if="image1 == ''"
                    @click="simulateInputfileClicked"
                  >
                    <!-- <v-btn centered outlined large fab class="ma-4"> -->
                    <v-icon centered class="mt-10 white--text"
                      >camera_alt</v-icon
                    >
                    <br />

                    <p class="text-center white--text">
                      {{
                        $store.state.language == "English"
                          ? "Company logo one*"
                          : "የኩባንያ አርማ አንድ*"
                      }}
                    </p>
                    <br />
                    <br />
                    <!-- </v-btn> -->
                  </div>
                </v-card>

                <v-card
                  style="background-color: #292732"
                  elevation="0"
                  light
                  @dragend="OnDragEnter"
                  @dragleave="OnDragLeave"
                  @dragover.prevent
                  @drop="OnDrop"
                  class="text-center mt-2"
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref="selectImage2"
                    style="display: none"
                    @change="OnInputFile2"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref="changeImage2"
                    style="display: none"
                    @change="OnInputFile2"
                  />

                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-0 mr-0"
                    :src="image12"
                    height="142"
                    contain
                    v-if="image12 != ''"
                    @click="changeImage2()"
                  ></v-img>

                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-5 mr-5 text-center"
                    v-if="image12 == ''"
                    @click="simulateInputfileClicked2"
                  >
                    <!-- <v-btn centered outlined large fab class="ma-4"> -->
                    <v-icon centered class="mt-10 white--text"
                      >camera_alt</v-icon
                    >
                    <br />

                    <p class="text-center white--text">
                      {{
                        $store.state.language == "English"
                          ? "Company logo two*"
                          : "የኩባንያ አርማ ሁለት*"
                      }}
                    </p>
                    <br />
                    <br />
                    <!-- </v-btn> -->
                  </div>
                </v-card>
              </v-flex>
              <!-- <v-flex xs12 class="">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-3 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="isZerf"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Does it has a sector?"
                        : "ዘርፍ አለው?"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>

              <v-flex xs6>
                <v-switch
                  hide-details
                  class="white--text pl-3 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="isDetail"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Read details"
                        : "ዝርዝሩን ያንብቡ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->
              <!-- 
              <v-flex xs6 class="pl-2">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="isTheSame"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Same Doc"
                        : "ተመሳሳይ ሰነድ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->

              <v-flex xs6 class="mt-3">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="isRegistration"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Registration Mandatory"
                        : "መመዝገብ ግዴታ ነው"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>
              <v-flex xs6 class="pl-2 mt-3">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="islinkedin"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Portfolio Required"
                        : "ፖርትፎሊዮ ያስፈልጋል"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>

              <v-flex xs6>
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="isCv"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "CV Mandatory"
                        : "CV ግዴታ ነው"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>
              <v-flex xs6 class="pl-2">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  v-model="isDirect"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Direct Contact"
                        : "በቀጥታ ያነጋግሩ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>

              <v-flex xs12 v-if="isDirect == 1">
                <input
                  required
                  v-model="phone"
                  class="pa-2 pl-3 mt-3"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Enter Phone Number or'
                      : 'ስልክ ቁጥር ያስገቡ ወይም'
                  "
                />
                <br />
                <input
                  required
                  v-model="tegcontact"
                  class="pa-2 pl-3 mt-3"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Tg Username @xyz, Web, Whats up, Facebook or'
                      : 'ቴሌግራም ስም  @xyz፣ ድህረገጽ፣ ዋትስአፕ፣ ፌስቡክ ወይም'
                  "
                />
                <br />

                <input
                  required
                  v-model="email"
                  class="pa-2 pl-3 mt-3"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Enter Email'
                      : 'ኢሜይል ያስገቡ'
                  "
                />
                <br />
              </v-flex>

              <v-flex xs6>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <input
                      @focus="unfocus"
                      :rules="[(v) => !!v || 'Deadline is required']"
                      v-bind="attrs"
                      v-on="on"
                      required
                      v-model="deadline"
                      class="pa-2 pl-3 mt-4"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Deadline Date*'
                          : 'የማብቂያ ቀን*'
                      "
                    />
                  </template>
                  <v-date-picker
                    :min="minDate"
                    v-model="deadline"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs6 class="pl-2">
                <input
                  required
                  v-model="age"
                  class="pa-2 pl-3 mt-4"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Age Range (Opt)'
                      : 'የዕድሜ ክልል'
                  "
                />
                <br />
              </v-flex>

              <v-flex xs12 class="text-center">
                <v-btn
                  :loading="submitLoading"
                  @click="submitPost"
                  class="pl-15 pr-15 mt-10 white--text"
                  text
                  style="
                    background-image: linear-gradient(#31ce5c, #242623);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                >
                  <span class="text-capitalize">Submit</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      v-model="applyDialog"
      fullscreen
      persistent
      v-if="applyData.length > 0"
    >
      <v-card style="background-color: #110f1a" class="white--text">
        <v-card-title>
          {{ $store.state.language == "English" ? "Apply" : "ያመልክቱ" }}
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            dark
            color="pink"
            text
            @click="
              applyDialog = false;
              applyData = [];
              $store.dispatch('changeParam', '');
            "
          >
            {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-6">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap class="pa-1 mt-1 white--text">
              <v-flex xs12 class="pl-1 mb-3">
                <h4>
                  {{
                    $store.state.language == "English"
                      ? "To apply for the position, please review the details thoroughly and provide all the required information."
                      : "ሥራውን ለማመልከት, እባክዎን ዝርዝሩን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያስገቡ"
                  }}
                </h4>
              </v-flex>

              <v-flex
                xs12
                v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                :key="i"
              >
                <span v-if="i == 0"
                  ><strong>Company Name: </strong>
                  {{ selectedDetail.company_name }}</span
                >

                <br v-if="i == 0" />
                <span v-if="i == 0"
                  ><strong>Company Address: </strong>
                  {{ selectedDetail.company_address }}</span
                >
                <br v-if="i == 0" />
                <br v-if="i == 0" />
                <span><strong>Title: </strong> {{ job }}</span>
                <br />
                <span
                  v-if="
                    selectedDetail.sex.split('@@cont@@')[i] != '' &&
                    selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                  "
                  ><strong>Applicants Needed: </strong>
                  {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                >
                <br
                  v-if="
                    selectedDetail.sex.split('@@cont@@')[i] != '' &&
                    selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                  "
                />
                <span
                  v-if="
                    selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                    selectedDetail.vacancies.split('@@cont@@')[i] != 'undefined'
                  "
                  ><strong>Vacancies: </strong>
                  {{ selectedDetail.vacancies.split("@@cont@@")[i] }}</span
                >

                <br
                  v-if="
                    selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                    selectedDetail.vacancies.split('@@cont@@')[i] != 'undefined'
                  "
                />
                <span
                  v-if="
                    selectedDetail.salary.split('@@cont@@')[i] != '' &&
                    selectedDetail.salary.split('@@cont@@')[i] != 'undefined'
                  "
                  ><strong>Salary: </strong>
                  {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                >

                <br
                  v-if="
                    selectedDetail.salary.split('@@cont@@')[i] != '' &&
                    selectedDetail.salary.split('@@cont@@')[i] != 'undefined'
                  "
                />
                <span
                  v-if="
                    selectedDetail.experience.split('@@cont@@')[i] != '' &&
                    selectedDetail.experience.split('@@cont@@')[i] !=
                      'undefined'
                  "
                  ><strong>Experience Level: </strong>
                  {{ selectedDetail.experience.split("@@cont@@")[i] }}</span
                >
                <br
                  v-if="
                    selectedDetail.experience.split('@@cont@@')[i] != '' &&
                    selectedDetail.experience.split('@@cont@@')[i] !=
                      'undefined'
                  "
                />
                <span
                  v-if="
                    selectedDetail.education.split('@@cont@@')[i] != '' &&
                    selectedDetail.education.split('@@cont@@')[i] != 'undefined'
                  "
                  ><strong>Education Qualification: </strong>
                  {{ selectedDetail.education.split("@@cont@@")[i] }}</span
                >
                <br
                  v-if="
                    selectedDetail.education.split('@@cont@@')[i] != '' &&
                    selectedDetail.education.split('@@cont@@')[i] != 'undefined'
                  "
                />
                <span
                  v-if="
                    selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                    selectedDetail.job_type.split('@@cont@@')[i] != 'undefined'
                  "
                  ><strong>Job Type: </strong>
                  {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                >
                <br
                  v-if="
                    selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                    selectedDetail.job_type.split('@@cont@@')[i] != 'undefined'
                  "
                />

                <div
                  class="mt-4"
                  v-if="i == selectedDetail.title.split('@@cont@@').length - 1"
                >
                  <span
                    v-if="
                      new Date(selectedDetail.deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                      Date.now()
                    "
                    ><strong>Deadline: </strong> {{ getDeadline }}</span
                  >

                  <span v-else class="pink--text"
                    ><strong>Deadline: </strong> ( {{ getDeadline }}),
                    Expired</span
                  >

                  <v-img
                    v-if="selectedDetail.short_image != ''"
                    class="ml-0 mr-0 mt-2 mb-3"
                    width="100%"
                    height="150"
                    :src="selectedDetail.short_image"
                    contain
                  ></v-img>

                  <span style="white-space: pre-line"
                    ><strong>Description: </strong>
                    {{ selectedDetail.detail_description }}</span
                  >

                  <br />

                  <br />

                  <br />

                  <span
                    v-if="
                      applyData[0].apply_link != '' &&
                      new Date(applyData[0].deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                        Date.now()
                    "
                  >
                    <span
                      style="white-space: pre-line mt-3"
                      v-if="
                        applyData[0].sex.includes($store.state.user.sex) ||
                        applyData[0].sex.includes('Both') ||
                        applyData[0].sex.includes('undefined')
                      "
                      ><strong>How To Apply: </strong> Contact the employer
                      using the given information bellow. <br />
                    </span>

                    <span
                      style="white-space: pre-line"
                      v-if="
                        applyData[0].sex.includes($store.state.user.sex) ||
                        applyData[0].sex.includes('Both') ||
                        applyData[0].sex.includes('undefined')
                      "
                      ><strong>Apply through: </strong>

                      <span
                        v-if="applyData[0].apply_link.split('.cont.')[0] != ''"
                      >
                        <a
                          @click="
                            copyNumber(
                              applyData[0].apply_link.split('.cont.')[0]
                            )
                          "
                        >
                          {{
                            applyData[0].apply_link.split(".cont.")[0] +
                            " " +
                            contactCopyMessage
                          }}</a
                        >
                        <br />
                      </span>

                      <span
                        v-if="applyData[0].apply_link.split('.cont.')[1] != ''"
                      >
                        <a
                          v-if="
                            applyData[0].apply_link
                              .split('.cont.')[1]
                              .startsWith('@')
                          "
                          :href="`https://t.me/${applyData[0].apply_link
                            .split('.cont.')[1]
                            .slice(1)}`"
                          >{{ applyData[0].apply_link.split(".cont.")[1] }}</a
                        >

                        <a
                          v-else
                          :href="applyData[0].apply_link.split('.cont.')[1]"
                          >{{ applyData[0].apply_link.split(".cont.")[1] }}</a
                        >
                        <br />
                      </span>

                      <span
                        v-if="applyData[0].apply_link.split('.cont.')[2] != ''"
                      >
                        <a> {{ applyData[0].apply_link.split(".cont.")[2] }}</a>
                        <br />
                      </span>
                    </span>

                    <span style="white-space: pre-line" v-else
                      ><strong class="pink--text mr-1"
                        >This job has a gender preference, so you are not able
                        to apply.</strong
                      >
                      <a
                        @click="
                          $store.dispatch('changeeditProfileDialog', true)
                        "
                      >
                        <span class="white--text"
                          >If you have not yet registered, please
                        </span>
                        Complete Registration</a
                      >
                    </span>
                  </span>

                  <div
                    v-if="
                      applyData[0].apply_link == '' &&
                      new Date(applyData[0].deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                        Date.now()
                    "
                  >
                    <span
                      v-if="
                        !applyClicked &&
                        (applyData[0].sex.includes($store.state.user.sex) ||
                          applyData[0].sex.includes('Both') ||
                          applyData[0].sex.includes('undefined'))
                      "
                      style="white-space: pre-line mt-3"
                      ><strong>How To Apply: </strong> Click the "Apply" button
                      bellow.
                    </span>
                    <div
                      class="text-center mb-15 pb-15"
                      v-if="
                        !applyClicked &&
                        (applyData[0].sex.includes($store.state.user.sex) ||
                          applyData[0].sex.includes('Both') ||
                          applyData[0].sex.includes('undefined'))
                      "
                    >
                      <v-btn
                        v-if="!isAlreadyApplied"
                        @click="applyClicked = true"
                        center
                        class="pl-15 pr-15 mt-7 mb-15 white--text"
                        text
                        style="
                          background-image: linear-gradient(#31ce5c, #242623);
                          border-radius: 10px;
                          font-size: 18px;
                        "
                      >
                        <span class="text-capitalize">Apply</span>
                      </v-btn>

                      <v-btn
                        v-else
                        center
                        class="pl-10 pr-10 mt-7 mb-15 white--text"
                        text
                        style="
                          background-image: linear-gradient(#31ce5c, #242623);
                          border-radius: 10px;
                          font-size: 18px;
                        "
                      >
                        <span class="text-capitalize">You Already Applied</span>
                      </v-btn>
                    </div>
                    <span
                      style="white-space: pre-line"
                      v-if="
                        !applyClicked &&
                        applyData[0].sex.includes($store.state.user.sex) &&
                        applyData[0].sex.includes('Both') &&
                        applyData[0].sex.includes('undefined')
                      "
                      ><strong class="pink--text mr-1"
                        >This job has a gender preference, so you are not able
                        to apply.</strong
                      >
                      <a
                        @click="
                          $store.dispatch('changeeditProfileDialog', true)
                        "
                      >
                        <span class="white--text"
                          >If you have not yet registered, please
                        </span>
                        Complete Registration</a
                      >
                    </span>
                  </div>

                  <div
                    v-if="
                      new Date(applyData[0].deadline_date).getTime() +
                        24 * 60 * 60 * 1000 <
                      Date.now()
                    "
                  >
                    <div class="text-center mb-15 pb-15">
                      <v-btn
                        center
                        class="pl-10 pr-10 mt-7 mb-15 white--text"
                        text
                        style="
                          background-image: linear-gradient(red, red);
                          border-radius: 10px;
                          font-size: 18px;
                        "
                      >
                        <span class="text-capitalize">Deadline Passed</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <br />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-text
          v-if="applyClicked"
          style="
            border-top: 4px solid #4f3606;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            color: white;
          "
          class="pt-5 pl-3 pr-3 pb-15"
        >
          <div
            class="pb-15 mb-15"
            v-if="
              applyClicked &&
              applyData[0].isregistered == 1 &&
              $store.state.user.first_name == ''
            "
          >
            <span
              >Your profile is currently incomplete. Please
              <a
                @click="$store.dispatch('changeeditProfileDialog', true)"
                class="blue--text"
                >Update</a
              >
              it with the necessary information to apply for this job. <br />
              <br />
            </span>
          </div>
          <div
            v-if="
              applyClicked &&
              ($store.state.user.first_name != '' ||
                applyData[0].isregistered == 0)
            "
          >
            <span
              >Please write a short note outlining your qualifications and
              explaining why you are a good fit for this position. This
              information will be taken into account when the employer reviews
              your application.</span
            >

            <textarea
              :rules="[(v) => !!v || 'Cover Letter is required']"
              required
              class="pa-2 pl-3 mt-3"
              v-model="coverLetter"
              :placeholder="
                $store.state.language == 'English'
                  ? 'Write a short cover letter'
                  : 'የሽፋን ደብዳቤ ይጻፉ'
              "
              rows="5"
              style="
                font-size: 16px;
                border-radius: 5px;
                background-color: #292732;
              "
            ></textarea>

            <input
              v-if="applyData[0].iszerf"
              v-model="sector"
              class="pa-2 pl-3 mt-2"
              style="
                font-size: 16px;
                border-radius: 5px;
                color: white;
                background-color: #292732;
              "
              type="text"
              :placeholder="
                $store.state.language == 'English'
                  ? 'Which sector do you prefer?'
                  : 'ዘርፍ ያስገቡ'
              "
            />
            <br />
            <input
              v-if="applyData[0].islinkedin && $store.state.user.link == ''"
              v-model="linkedin"
              class="pa-2 pl-3 mt-3"
              style="
                font-size: 16px;
                border-radius: 5px;
                color: white;
                background-color: #292732;
              "
              type="text"
              :placeholder="
                $store.state.language == 'English'
                  ? 'Include your Portfolio, LinkedIn or GitHub.'
                  : 'የ LinkedIn, Portfolio ወይም GitHub መገለጫህን አስገባ።'
              "
            />

            <v-card
              v-if="applyData[0].iscv"
              style="background-color: #292732"
              elevation="0"
              light
              @dragend="OnDragEnter"
              @dragleave="OnDragLeave"
              @dragover.prevent
              @drop="OnDrop"
              class="text-center mt-3 pb-2"
            >
              <input
                type="file"
                accept=".pdf"
                ref="selectCv"
                style="display: none"
                @change="handleFileChange"
              />

              <div
                @mouseenter="changeCursor"
                id="imageCamera"
                class="ml-5 mr-5 text-center"
                @click="simulateInputfileCV"
              >
                <!-- <v-btn centered outlined large fab class="ma-4"> -->
                <v-icon large centered class="mt-4 white--text"
                  >file_upload</v-icon
                >
                <br />

                <p
                  class="text-center white--text mt-1"
                  v-if="pdfUploadStatus == ''"
                >
                  <span v-if="$store.state.user.cv == ''">{{
                    $store.state.language == "English"
                      ? "Attach Your CV"
                      : "CV ያያይዙ"
                  }}</span>

                  <span v-else>{{
                    $store.state.language == "English"
                      ? "Your CV already attached. Tap here to change"
                      : "ሲቪዎ አስቀድሞ ተያይዟል \n ለመቀየር ነካ ያድርጉ"
                  }}</span>
                </p>

                <p class="text-center white--text mt-1" v-else>
                  {{ pdfUploadStatus }}
                </p>
              </div>
            </v-card>
          </div>

          <div
            v-if="
              applyClicked &&
              ($store.state.user.first_name != '' ||
                applyData[0].isregistered == 0)
            "
            class="text-center"
          >
            <v-btn
              v-if="!isAlreadyApplied"
              :loading="submitLoading"
              @click="applyJob"
              center
              class="pl-10 pr-10 mt-5 white--text"
              text
              style="
                background-image: linear-gradient(#31ce5c, #242623);
                border-radius: 10px;
                font-size: 18px;
              "
            >
              <span class="text-capitalize">Complete Application </span>
            </v-btn>

            <v-btn
              v-else
              center
              class="pl-10 pr-10 mt-5 white--text"
              text
              style="
                background-image: linear-gradient(#31ce5c, #242623);
                border-radius: 10px;
                font-size: 18px;
              "
            >
              <span class="text-capitalize">You Already Applied </span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

import profileHeader from "./profileHeader.vue";

import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
    profileHeader,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,
      dateDialog: false,
      deadline: "",
      menu: false,

      valid: true,
      submitLoading: false,
      isDetail: 1,
      isDirect: 0,
      isRegistration: 0,
      isCv: 0,
      islinkedin: 0,
      isZerf: 0,
      isTheSame: 1,

      age: "",
      tgid: "",
      companyName: "",
      title: [],
      vacancies: [],
      sex: [],
      salary: [],
      experience: [],
      education: [],
      jobType: [],
      fieldStudy: [],
      tegcontact: "",
      phone: "",
      email: "",
      postCounter: 1,

      postJobDialog: false,

      shortDescription: "",
      detailDescription: "",
      address: "",
      applyLink: "",
      language: "",

      image1: "",
      imageUrl1: "",

      image12: "",
      imageUrl12: "",
      files: [],
      files2: [],
      allPosts: [],
      activeJobs: 0,
      pendingJobs: 0,
      rejectedJobs: 0,
      expiredJobs: 0,
      applyData: [],
      selectedDetail: "",
      applyDialog: false,
      applyClicked: false,
      coverLetter: "",
      sector: "",
      linkedin: "",
      pdf: null,
      cvUrl: "",
      indexoFChecked: -1,

      pdfUploadStatus: false,

      allApplications: [],
      contactPhone: "0918440700",
      contactCopyMessage: "",
      acceptedApplications: 0,
      rejectedApplications: 0,
      isAlreadyApplied: false,
      editProfileDialog: false,
      domainUrl: "https://kinu.abimabusiness.com",
      channelName: "https://t.me/test_jobs",
      jobTypLists: [
        "Full-Time",
        "Part-Time",
        "Remote",
        "Contract",
        "Temporary",
        "Freelancer",
        "Internship",
        "Volunteer",
      ],

      educationLists: [
        "8+ (Above 8th-grade)",
        "10+ (Above 10th-grade)",
        "12+ (Completed 12th-grade)",
        "TVET",
        "Diploma",
        "Bachelor's Degree",
        "Master's Degree",
        "Not Required",
      ],
      minDate: new Date().toISOString().substr(0, 10),
    };
  },

  computed: {
    getDeadline() {
      return this.formatDate(this.applyData[0].deadline_date);
    },

    getEducation() {
      return this.education[this.indexoFChecked];
    },
  },

  methods: {
    callTo(item, index) {
      this.education[index] = item.toString();
      // this.indexoFChecked = index;
    },
    shareChannel() {
      const url = this.channelName;
      const text = "Join our job posting telegram channel";
      // Opens the Telegram interface where the user can select friends or groups to share
      window.open(
        `https://t.me/share/url?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(text)}`,
        "_blank"
      );
    },
    unfocus(event) {
      event.target.blur();
    },

    async getUserPic() {
      const botToken = "7433805398:AAG4xsYgRB0rw3UWrS9dMV1cj44xBT7TcWE";
      const userId = this.$store.state.user.tg_id;
      var resultUrl = "";

      await fetch(
        `https://api.telegram.org/bot${botToken}/getUserProfilePhotos?user_id=${userId}`
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.ok && data.result.total_count > 0) {
            // You can access the file_id of the first profile picture
            const fileId = data.result.photos[0][0].file_id;

            // Fetch the file URL using the file_id
            await fetch(
              `https://api.telegram.org/bot${botToken}/getFile?file_id=${fileId}`
            )
              .then((response) => response.json())
              .then((fileData) => {
                const filePath = fileData.result.file_path;
                resultUrl = `https://api.telegram.org/file/bot${botToken}/${filePath}`;
              });
          }
        })
        .catch((err) => {});

      return resultUrl;
    },

    copyNumber(contactPhone) {
      navigator.clipboard.writeText(contactPhone).then(() => {
        this.contactCopyMessage = "Phone number copied";

        setTimeout(() => {
          this.contactCopyMessage = "";
        }, 3000);
      });
    },
    async handleFileChange(event) {
      this.pdf = event.target.files[0];
      this.pdfUploadStatus = "Uploading...";

      const formData = new FormData();
      formData.append("pdf", this.pdf);
      this.cvUrl = "";
      try {
        const response = await axios.post(
          `${this.domainUrl}/uploadpdf`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.cvUrl = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.pdfUploadStatus = "Uploading Error, Please try again" + error;
        return;
      }

      if (this.cvUrl != "") {
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                attachCv(
                  user_id:${parseInt(this.$store.state.user.user_id)}
                  cvurl:"${this.cvUrl}"


                  ){
                 statusMessage
              }

              }
              `,
            },
          });

          if (result.data.data.attachCv[0].statusMessage.toString() == "true") {
            this.getAccountInfo();
            this.pdfUploadStatus = "Uploaded Successfully!.";
          } else this.pdfUploadStatus = "Uploading Error, Please try again";
        } catch (err) {
          this.pdfUploadStatus = "Uploading Error, Please try again";
        }
      } else this.pdfUploadStatus = "Uploading Error, Please try again";

      // uploadTask.on(
      //   "state_changed",
      //   (snapshot) => {
      //     this.pdfUploadStatus = "Uploading...";
      //   },
      //   (error) => {
      //     this.pdfUploadStatus = "Uploading Error, Try again";
      //   },
      //   () => {
      //     // Handle successful uploads
      //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      //       alert("File available at", downloadURL);

      //       this.pdfUploadStatus = "Uploading Success!.";
      //     });
      // //   }
      // );
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },

    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();

      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
    },

    OnDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;

      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        alert(`${file.name} is not an image`);
        return;
      }

      const reader = new FileReader();
      reader.onload = async (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    async uploadImage(fileToUpload, imageNumber) {
      const formData = new FormData();
      formData.append("image", fileToUpload);

      try {
        const response = await axios.post(
          `${this.domainUrl}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageNumber == 1)
          this.imageUrl1 = this.domainUrl + response.data.filePath;
        else this.imageUrl12 = this.domainUrl + response.data.filePath;
      } catch (error) {
        alert("Error uploading image:", error);
      }
    },

    //image2

    OnInputFile2(e) {
      const files = e.target.files;
      this.files2 = e.target.files;
      Array.from(files).forEach((file) => this.addImage2(file));
    },
    simulateInputfileClicked2() {
      this.$refs.selectImage2.click();
    },

    simulateInputfileCV() {
      this.$refs.selectCv.click();
    },
    addImage2(file) {
      if (!file.type.match("image.*")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (f) => {
        this.image12 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage2() {
      this.$refs.changeImage2.click();
    },
    async uploadImage2() {
      var storageReference = this.$storage.ref(
        "jobimages/image" + Math.random() + ".jpg"
      );
      try {
        await storageReference.put(this.files2[0]);
        this.imageUrl12 = await storageReference.getDownloadURL();
      } catch (err) {
        alert(err.message);
      }
    },

    // formatDate(date) {
    //   var d = new Date(date),
    //     month = "" + (d.getMonth() + 1),
    //     day = "" + d.getDate(),
    //     year = d.getFullYear();

    //   if (month.length < 2) month = "0" + month;
    //   if (day.length < 2) day = "0" + day;

    //   return [year, month, day].join("-");
    // },

    async getAllData() {
      this.pageLoading = true;
      try {
        let result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllPosts(tg_id:"${this.$store.state.user.tg_id}"){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id
                company_name
                vacancies
                   salary
               experience
                education
                job_type



           }
      }
      `,
          },
        });

        this.allPosts = result.data.data.getAllPosts;
        this.expiredJobs = 0;
        this.rejectedJobs = 0;
        this.pendingJobs = 0;
        this.activeJobs = 0;
        for (let i = 0; i < this.allPosts.length; i++) {
          if (
            new Date(this.allPosts[i].deadline_date).getTime() <
              Date.now() - 24 * 60 * 60 * 1000 &&
            this.allPosts[i].isrejected == 0
          ) {
            this.expiredJobs++;
          }

          if (this.allPosts[i].isrejected == 1) {
            this.rejectedJobs++;
          }

          if (
            new Date(this.allPosts[i].deadline_date).getTime() +
              24 * 60 * 60 * 1000 >
              Date.now() &&
            this.allPosts[i].isrejected == 0 &&
            this.allPosts[i].isapproved == 0
          ) {
            this.pendingJobs++;
          }

          if (
            new Date(this.allPosts[i].deadline_date).getTime() +
              24 * 60 * 60 * 1000 >
              Date.now() &&
            this.allPosts[i].isrejected == 0 &&
            this.allPosts[i].isapproved == 1
          ) {
            this.activeJobs++;
          }
        }
      } catch (err) {
        alert(err.message);
      }

      try {
        let resultapp = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllApplications(user_id:${parseInt(this.$store.state.user.user_id)}){
                       application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted


           }
      }
      `,
          },
        });

        this.allApplications = resultapp.data.data.getAllApplications;
        this.acceptedApplications = 0;
        this.rejectedApplications = 0;

        for (let k = 0; k < this.allApplications.length; k++) {
          if (this.applyData.length > 0)
            if (this.applyData[0].post_id == this.allApplications[k].post_id) {
              this.isAlreadyApplied = true;
            }

          if (this.allApplications[k].isaccepted == 1) {
            this.acceptedApplications = this.acceptedApplications + 1;
          } else if (this.allApplications[k].isaccepted == 2) {
            this.rejectedApplications = this.rejectedApplications + 1;
          }
        }
      } catch (err) {}
      this.pageLoading = false;
    },

    async getApplyData() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getJobById(post_id: ${parseInt(this.$store.state.paramId.toString())}){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id
                company_name
   vacancies
      salary
  experience
   education
   job_type


           }
      }
      `,
          },
        });

        this.applyData = result.data.data.getJobById;
        this.selectedDetail = this.applyData[0];

        this.applyDialog = true;
      } catch (err) {}
    },

    async submitPost() {
      this.imageUrl1 = "";
      this.imageUrl12 = "";

      let isTitleEmpty = false;

      let tempjobTitle = "";
      let tempSex = "";
      let tempVacancies = "";
      let tempExperience = "";
      let tempEducation = "";

      let tempJobType = "";
      let tempSalary = "";

      for (let i = 0; i < this.postCounter; i++) {
        if (this.title[i] == "" || this.title[i] == undefined) {
          isTitleEmpty = true;
        }
        if (tempjobTitle == "") {
          tempjobTitle = this.title[i];
        } else {
          tempjobTitle = tempjobTitle + "@@cont@@" + this.title[i];
        }

        if (tempSex == "") {
          tempSex = this.sex[i];
        } else {
          tempSex = tempSex + "@@cont@@" + this.sex[i];
        }

        if (tempVacancies == "") {
          tempVacancies = this.vacancies[i];
        } else {
          tempVacancies = tempVacancies + "@@cont@@" + this.vacancies[i];
        }

        if (tempExperience == "") {
          tempExperience = this.experience[i];
        } else {
          tempExperience = tempExperience + "@@cont@@" + this.experience[i];
        }

        if (tempEducation == "") {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined
          ) {
            if (this.fieldStudy[i] != "" && this.fieldStudy[i] != undefined) {
              tempEducation = this.education[i] + ", in " + this.fieldStudy[i];
            } else {
              tempEducation = this.education[i];
            }
          } else {
            tempEducation = this.education[i];
          }
        } else {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined
          ) {
            if (this.fieldStudy[i] != "" && this.fieldStudy[i] != undefined) {
              tempEducation =
                tempEducation +
                "@@cont@@" +
                this.education[i] +
                ", in " +
                this.fieldStudy[i];
            } else {
              tempEducation = tempEducation + "@@cont@@" + this.education[i];
            }
          } else {
            tempEducation = tempEducation + "@@cont@@" + this.education[i];
          }
        }

        if (tempJobType == "") {
          tempJobType = this.jobType[i];
        } else {
          tempJobType = tempJobType + "@@cont@@" + this.jobType[i];
        }

        if (tempSalary == "") {
          tempSalary = this.salary[i];
        } else {
          tempSalary = tempSalary + "@@cont@@" + this.salary[i];
        }
      }
      if (isTitleEmpty) {
        alert(
          this.$store.state.language == "English"
            ? "Please enter title"
            : "እባክዎ የስራውን ርእስ ያስገቡ"
        );
        return;
      }

      if (this.files.length < 1) {
        alert("Please upload campany logo one");
        return;
      }

      if (this.files2.length < 1) {
        alert("Please upload campany logo two");
        return;
      }

      if (this.companyName == "") {
        alert("Please enter company name");
        return;
      }

      if (this.detailDescription == "") {
        alert("Please enter detail description");
        return;
      }

      if (this.address == "") {
        alert("Please enter company address");
        return;
      }

      if (this.deadline == "") {
        alert("Please enter deadline date");
        return;
      }

      if (this.isDirect == 1) {
        if (this.tegcontact == "" && this.phone == "" && this.email == "") {
          alert(
            "You chose direct contact,  but you haven’t provided your contact information. Please include your contact information from the options"
          );
          return;
        }

        this.applyLink = "";
        this.applyLink =
          this.phone + ".cont." + this.tegcontact + ".cont." + this.email;
      }

      let tempZerf = this.title.length > 1 ? 1 : 0;

      this.submitLoading = true;

      // if (this.education != "" && this.education != "Not Required") {
      //   if (this.fieldStudy != "")
      //     this.education = this.education + " in " + this.fieldStudy;
      // }

      try {
        if (this.files[0] != "") {
          await this.uploadImage(this.files[0], 1);
        }

        if (this.files2[0] != "") {
          await this.uploadImage(this.files2[0], 2);
        }

        if (this.imageUrl1 == "" || this.imageUrl12 == "") {
          alert("The logo did not upload. Please try again.");
          this.submitLoading = false;
          return;
        }
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        axios.defaults.headers.get["Accept"] = "application/json;charset=UTF-8";
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,
          data: {
            query: `
                 mutation {
                  addPost(
                   tg_id:"${parseInt(this.tgid)}"
                    title:"${tempjobTitle}"
                    short_description:"${this.shortDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    short_image:"${this.imageUrl1}"

                    detail_description:"${this.detailDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    detail_image:"${this.imageUrl12}"
                    company_address:"${this.address}"
                    deadline_date:"${this.deadline}"

                    application_format:"${""}"
                    sex:"${tempSex}"
                    isdetail:${this.isDetail + 0}
                    isdirect: ${this.isDirect + 0}
                    apply_link:"${this.applyLink}"
                    isregistered: ${this.isRegistration + 0}

                    iscv: ${this.isCv + 0}
                    islinkedin: ${this.islinkedin + 0}
                    iszerf: ${tempZerf + 0}
                    isthesame: ${this.isTheSame + 0}
                    age_above_bellow:"${this.age}"
                    language: "${this.$store.state.language}"

                    company_name:"${this.companyName}"
                    vacancies:"${tempVacancies}"
                    salary:"${tempSalary}"
                    experience:"${tempExperience}"
                    education:"${tempEducation}"
                    job_type:"${tempJobType}"

                    ){
                        statusMessage
                    }
                    }
              `,
          },
        });
        if (result.data.data.addPost[0].statusMessage.toString() == "true") {
          alert(
            this.$store.state.language == "English"
              ? "Your Job is submited successfully, Please wait until it is approved by Admin."
              : "ሥራዎ በተሳካ ሁኔታ ተመዝግቧል፣ እባክዎ በአስተዳዳሪው እስኪፀድቅ ድረስ ይጠብቁ።"
          );
          this.postJobDialog = false;
          await this.getAllData();
        } else {
          alert(
            this.$store.state.language == "English"
              ? "Something went wrong, please try again"
              : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      } catch (err) {
        alert(err.message);
      }
      this.submitLoading = false;
    },

    async applyJob() {
      if (this.applyData[0].isregistered == 1) {
        if (
          this.$store.state.user.first_name == "" ||
          this.$store.state.user.last_name == "" ||
          this.$store.state.user.phone_number == "" ||
          this.$store.state.user.phone_number == ""
        ) {
          alert(
            "Your registration is not complete. Please complete it before moving on."
          );
          return;
        }
      }
      if (this.applyData[0].iscv == 1) {
        if (this.$store.state.user.cv == "") {
          alert("A CV is required. Please attach your CV or resume.");
          return;
        }
      }

      if (this.applyData[0].islinkedin == 1) {
        if (this.linkedin == "" && this.$store.state.user.link == "") {
          alert("LinkedIn or GitHub profile is required");
          return;
        }
      }

      if (this.applyData[0].iszerf == 1) {
        if (this.sector == "") {
          alert("Sector required");
          return;
        }
      }

      if (this.coverLetter.length < 40) {
        alert("Cover letter must be greater than 40 characters");
        return;
      }

      if (1 == 1) {
        this.submitLoading = true;
        try {
          axios.defaults.headers.post["Content-Type"] =
            "application/json;charset=UTF-8";
          axios.defaults.headers.get["Accept"] =
            "application/json;charset=UTF-8";
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `
                 mutation {
                  applyJob(
                   post_id:${this.applyData[0].post_id}
                   tg_id:"${this.applyData[0].tg_id}"
                    user_id:${parseInt(this.$store.state.user.user_id)}
                    cover_letter:"${this.coverLetter
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    sector:"${this.sector}"
                    linkedin:"${this.linkedin}"

                    ){
                        statusMessage
                    }
                    }
              `,
            },
          });
          if (result.data.data.applyJob[0].statusMessage.toString() == "true") {
            alert(
              this.$store.state.language == "English"
                ? "Application Submitted Successfully"
                : "ማመልከቻው በተሳካ ሁኔታ ተጠናቋል።"
            );
            this.applyDialog = false;
            this.applyData = [];
            this.selectedDetail = "";
            this.$store.dispatch("changeParam", "");
            await this.getAllData();
          } else {
            alert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
          }
        } catch (err) {
          alert(err.message);
        }
        this.submitLoading = false;
      } else
        alert(
          this.$store.state.language == "English"
            ? " Please read carefully and complete all the required fields."
            : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያጠናቅቁ።"
        );
    },

    async getAccountInfo() {
      var profilePictureUrl = await this.getUserPic();

      if (profilePictureUrl == undefined) profilePictureUrl = "";
      try {
        var userLoginResult = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
               userLogin(
               tg_id:"${this.$store.state.user.tg_id}"
                  user_pic: "${profilePictureUrl}"
                  tg_username: "${this.$store.state.user.tg_username}"
               ){
                     user_id
                      tg_id
                      first_name
                      middle_name
                      last_name
                      user_pic
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                      tg_username
                      statusMessage

                   }
              }
              `,
          },
        });

        if (userLoginResult.data.data.userLogin.length > 0) {
          if (profilePictureUrl != "")
            userLoginResult.data.data.userLogin[0].user_pic = profilePictureUrl;
          this.$store.dispatch(
            "loginUser",
            userLoginResult.data.data.userLogin[0]
          );
        }
      } catch (err) {}
    },
  },

  async created() {
    this.applyData = [];
    if (this.$store.state.user != "") {
      if (
        this.$store.state.paramId != "" &&
        this.$store.state.paramId != null
      ) {
        await this.getApplyData();
      }
      this.$store.dispatch("changeeditProfileDialog", false);
      await this.getAllData();
      this.getAccountInfo();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
}

#userAvt {
  background-color: #e96910;
}

input {
  width: 100%;
  color: white;
}

input::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  width: 100%;
  color: white;
}

textarea::placeholder {
  color: white;
  opacity: 1;
}

#imageCamera {
  background-color: #292732;
  font-size: 16px;
}

.v-radio .v-icon {
  color: red !important;
}

#divider {
  border-color: rgb(81, 80, 80);
}
</style>
