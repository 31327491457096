<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" v-if="!pageLoading" class="pl-1 pr-1">
          <profileHeader />

          <div style="margin-top: -28px">
            <h4 class="text-center white--text pb-5">
              You have applied {{ allApplications.length }}
              {{ allApplications.length == 1 ? " Job" : " Jobs" }}
            </h4>
            <br />

            <v-layout
              row
              wrap
              class="white--text"
              v-for="(post, i) in allApplications"
              :key="i"
            >
              <v-flex xs8 class="pl-2 pr-1">
                <span
                  ><strong>{{
                    post.getJobs[0].title.replace("@@cont@@", ",")
                  }}</strong>
                </span>
                <br />

                <span class="truncate-text grey--text">{{
                  post.getJobs[0].detail_description
                }}</span>
              </v-flex>

              <v-flex xs4 class="pr-1 text-right pt-2">
                <span v-if="post.isaccepted == 2" class="pink--text"
                  >Rejected</span
                >

                <span v-if="post.isaccepted == 0" class="orange--text"
                  >Pending</span
                >

                <Span v-if="post.isaccepted == 1" class="green--text"
                  >Accepted</Span
                >
                <v-btn
                  x-small
                  text
                  class="ml-2"
                  @click="
                    selectedDetail = post;
                    detailDialog = true;
                  "
                >
                  <v-icon class="grey--text">visibility</v-icon></v-btn
                >
              </v-flex>

              <v-flex xs12 class="mt-2 mb-2">
                <v-divider style="border-color: #292732"></v-divider>
              </v-flex>
            </v-layout>
          </div>

          <v-dialog
            transition="dialog-bottom-transition"
            v-model="detailDialog"
            fullscreen
            persistent
            v-if="selectedDetail != ''"
          >
            <v-card style="background-color: #110f1a" class="white--text">
              <v-card-title>
                {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="pink"
                  text
                  @click="
                    detailDialog = false;
                    selectedDetail = '';
                  "
                >
                  {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
                </v-btn>
              </v-card-title>

              <v-card-text class="pb-15">
                <v-form ref="form" lazy-validation>
                  <v-layout row wrap class="pa-1 mt-1 white--text">
                    <v-flex
                      xs12
                      v-for="(job, i) in selectedDetail.getJobs[0].title.split(
                        '@@cont@@'
                      )"
                      :key="i"
                    >
                      <span v-if="i == 0"
                        ><strong>Company Name: </strong>
                        {{ selectedDetail.getJobs[0].company_name }}</span
                      >

                      <br v-if="i == 0" />
                      <span v-if="i == 0"
                        ><strong>Company Address: </strong>
                        {{ selectedDetail.getJobs[0].company_address }}</span
                      >
                      <br v-if="i == 0" />
                      <br v-if="i == 0" />
                      <span><strong>Title: </strong> {{ job }}</span>
                      <br />
                      <span
                        v-if="
                          selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                            '' &&
                          selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Applicants Needed: </strong>
                        {{
                          selectedDetail.getJobs[0].sex.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                            '' &&
                          selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                        ><strong>Vacancies: </strong>
                        {{
                          selectedDetail.getJobs[0].vacancies.split("@@cont@@")[
                            i
                          ]
                        }}</span
                      >

                      <br
                        v-if="
                          selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.getJobs[0].salary.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].salary.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                        ><strong>Salary: </strong>
                        {{
                          selectedDetail.getJobs[0].salary.split("@@cont@@")[i]
                        }}</span
                      >

                      <br
                        v-if="
                          selectedDetail.getJobs[0].salary.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].salary.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.getJobs[0].experience.split(
                            '@@cont@@'
                          )[i] != '' &&
                          selectedDetail.getJobs[0].experience.split(
                            '@@cont@@'
                          )[i] != 'undefined'
                        "
                        ><strong>Experience Level: </strong>
                        {{
                          selectedDetail.getJobs[0].experience.split(
                            "@@cont@@"
                          )[i]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.getJobs[0].experience.split(
                            '@@cont@@'
                          )[i] != '' &&
                          selectedDetail.getJobs[0].experience.split(
                            '@@cont@@'
                          )[i] != 'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.getJobs[0].education.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].education.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                        ><strong>Education Qualification: </strong>
                        {{
                          selectedDetail.getJobs[0].education.split("@@cont@@")[
                            i
                          ]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.getJobs[0].education.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].education.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                        ><strong>Job Type: </strong>
                        {{
                          selectedDetail.getJobs[0].job_type.split("@@cont@@")[
                            i
                          ]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                            i
                          ] != '' &&
                          selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                            i
                          ] != 'undefined'
                        "
                      />

                      <div
                        class="mt-4"
                        v-if="
                          i ==
                          selectedDetail.getJobs[0].title.split('@@cont@@')
                            .length -
                            1
                        "
                      >
                        <span
                          v-if="
                            new Date(
                              selectedDetail.getJobs[0].deadline_date
                            ).getTime() +
                              24 * 60 * 60 * 1000 >
                            Date.now()
                          "
                          ><strong>Deadline: </strong> {{ getDeadline }}</span
                        >

                        <span v-else class="pink--text"
                          ><strong>Deadline: </strong> ( {{ getDeadline }}),
                          Expired</span
                        >

                        <v-img
                          v-if="selectedDetail.getJobs[0].short_image != ''"
                          class="ml-0 mr-0 mt-2 mb-3"
                          width="100%"
                          height="150"
                          :src="selectedDetail.getJobs[0].short_image"
                          contain
                        ></v-img>

                        <span style="white-space: pre-line"
                          ><strong>Description: </strong>
                          {{
                            selectedDetail.getJobs[0].detail_description
                          }}</span
                        >

                        <br />

                        <br />
                        <span style="white-space: pre-line"
                          ><strong>Your Application Letter: <br /> </strong>
                          {{ selectedDetail.cover_letter }}</span
                        >
                        <br />
                        <span
                          style="white-space: pre-line"
                          v-if="selectedDetail.sector != ''"
                          ><strong>Your selected sector is: <br /> </strong>
                          {{ selectedDetail.sector }}</span
                        >
                      </div>
                      <br />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
    allApplications: [],
    detailDialog: false,
    selectedDetail: [],
  }),

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.getJobs[0].deadline_date);
    },
  },

  methods: {
    async getApplyData() {
      this.pageLoading = true;

      try {
        let resultapp = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllApplications(user_id:${parseInt(this.$store.state.user.user_id)}){
                       application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted
                        getJobs{
                       post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id
                  company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
                        }


           }
      }
      `,
          },
        });

        this.allApplications = resultapp.data.data.getAllApplications;
      } catch (err) {}
      this.pageLoading = false;
    },

    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },
  created() {
    this.getApplyData();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

.studyText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 1; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
.letterText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

#userAvt {
  background-color: #e96910;
}
</style>
