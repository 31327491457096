var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.$store.state.user != '')?_c('div',{staticClass:"mt-1",staticStyle:{"font-size":"15px"}},[(_vm.pageLoading)?_c('div',{staticClass:"text-center mt-15"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"grey","indeterminate":""}})],1):_vm._e(),(!_vm.pageLoading)?_c('div',{staticClass:"pl-1 pr-1",attrs:{"id":"homePage"}},[_c('profileHeader'),_c('div',{staticStyle:{"margin-top":"-28px"}},[_c('h4',{staticClass:"text-center white--text pb-5"},[_vm._v(" You have applied "+_vm._s(_vm.allApplications.length)+" "+_vm._s(_vm.allApplications.length == 1 ? " Job" : " Jobs")+" ")]),_c('br'),_vm._l((_vm.allApplications),function(post,i){return _c('v-layout',{key:i,staticClass:"white--text",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pl-2 pr-1",attrs:{"xs8":""}},[_c('span',[_c('strong',[_vm._v(_vm._s(post.getJobs[0].title.replace("@@cont@@", ",")))])]),_c('br'),_c('span',{staticClass:"truncate-text grey--text"},[_vm._v(_vm._s(post.getJobs[0].detail_description))])]),_c('v-flex',{staticClass:"pr-1 text-right pt-2",attrs:{"xs4":""}},[(post.isaccepted == 2)?_c('span',{staticClass:"pink--text"},[_vm._v("Rejected")]):_vm._e(),(post.isaccepted == 0)?_c('span',{staticClass:"orange--text"},[_vm._v("Pending")]):_vm._e(),(post.isaccepted == 1)?_c('Span',{staticClass:"green--text"},[_vm._v("Accepted")]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","text":""},on:{"click":function($event){_vm.selectedDetail = post;
                  _vm.detailDialog = true;}}},[_c('v-icon',{staticClass:"grey--text"},[_vm._v("visibility")])],1)],1),_c('v-flex',{staticClass:"mt-2 mb-2",attrs:{"xs12":""}},[_c('v-divider',{staticStyle:{"border-color":"#292732"}})],1)],1)})],2),(_vm.selectedDetail != '')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","fullscreen":"","persistent":""},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('v-card',{staticClass:"white--text",staticStyle:{"background-color":"#110f1a"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Detail" : "ዝርዝር")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"pink","text":""},on:{"click":function($event){_vm.detailDialog = false;
                  _vm.selectedDetail = '';}}},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Close" : "ዝጋ")+" ")])],1),_c('v-card-text',{staticClass:"pb-15"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-layout',{staticClass:"pa-1 mt-1 white--text",attrs:{"row":"","wrap":""}},_vm._l((_vm.selectedDetail.getJobs[0].title.split(
                      '@@cont@@'
                    )),function(job,i){return _c('v-flex',{key:i,attrs:{"xs12":""}},[(i == 0)?_c('span',[_c('strong',[_vm._v("Company Name: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].company_name))]):_vm._e(),(i == 0)?_c('br'):_vm._e(),(i == 0)?_c('span',[_c('strong',[_vm._v("Company Address: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].company_address))]):_vm._e(),(i == 0)?_c('br'):_vm._e(),(i == 0)?_c('br'):_vm._e(),_c('span',[_c('strong',[_vm._v("Title: ")]),_vm._v(" "+_vm._s(job))]),_c('br'),(
                        _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          '' &&
                        _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          'undefined'
                      )?_c('span',[_c('strong',[_vm._v("Applicants Needed: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].sex.split("@@cont@@")[i]))]):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          '' &&
                        _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          'undefined'
                      )?_c('br'):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('span',[_c('strong',[_vm._v("Vacancies: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].vacancies.split("@@cont@@")[ i ]))]):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('br'):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('span',[_c('strong',[_vm._v("Salary: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].salary.split("@@cont@@")[i]))]):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('br'):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].experience.split(
                          '@@cont@@'
                        )[i] != '' &&
                        _vm.selectedDetail.getJobs[0].experience.split(
                          '@@cont@@'
                        )[i] != 'undefined'
                      )?_c('span',[_c('strong',[_vm._v("Experience Level: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].experience.split( "@@cont@@" )[i]))]):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].experience.split(
                          '@@cont@@'
                        )[i] != '' &&
                        _vm.selectedDetail.getJobs[0].experience.split(
                          '@@cont@@'
                        )[i] != 'undefined'
                      )?_c('br'):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('span',[_c('strong',[_vm._v("Education Qualification: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].education.split("@@cont@@")[ i ]))]):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('br'):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('span',[_c('strong',[_vm._v("Job Type: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].job_type.split("@@cont@@")[ i ]))]):_vm._e(),(
                        _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != '' &&
                        _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      )?_c('br'):_vm._e(),(
                        i ==
                        _vm.selectedDetail.getJobs[0].title.split('@@cont@@')
                          .length -
                          1
                      )?_c('div',{staticClass:"mt-4"},[(
                          new Date(
                            _vm.selectedDetail.getJobs[0].deadline_date
                          ).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        )?_c('span',[_c('strong',[_vm._v("Deadline: ")]),_vm._v(" "+_vm._s(_vm.getDeadline))]):_c('span',{staticClass:"pink--text"},[_c('strong',[_vm._v("Deadline: ")]),_vm._v(" ( "+_vm._s(_vm.getDeadline)+"), Expired")]),(_vm.selectedDetail.getJobs[0].short_image != '')?_c('v-img',{staticClass:"ml-0 mr-0 mt-2 mb-3",attrs:{"width":"100%","height":"150","src":_vm.selectedDetail.getJobs[0].short_image,"contain":""}}):_vm._e(),_c('span',{staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v("Description: ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].detail_description))]),_c('br'),_c('br'),_c('span',{staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v("Your Application Letter: "),_c('br')]),_vm._v(" "+_vm._s(_vm.selectedDetail.cover_letter))]),_c('br'),(_vm.selectedDetail.sector != '')?_c('span',{staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v("Your selected sector is: "),_c('br')]),_vm._v(" "+_vm._s(_vm.selectedDetail.sector))]):_vm._e()],1):_vm._e(),_c('br')])}),1)],1)],1)],1)],1):_vm._e()],1):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }