<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" v-if="!pageLoading" class="pl-1 pr-1">
          <profileHeader />

          <div style="margin-top: -20px">
            <p class="text-center white--text pb-3">
              {{
                $store.state.language == "English"
                  ? "No alert has been found."
                  : "እስካሁን ምንም ማሳወቅያ አልተገኘም"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
  }),

  methods: {},
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.2;
}

#userAvt {
  background-color: #e96910;
}
</style>
