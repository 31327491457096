import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "",
    userURL: "https://kinu.abimabusiness.com/graphql",

    language: "English",
    whichBtn: "home",
    paramId: "",
    editProfileDialog: false,
  },
  getters: {},
  mutations: {
    changeLanguage(state, language) {
      state.language = language;
    },

    changeeditProfileDialog(state, editProfileDialog) {
      state.editProfileDialog = editProfileDialog;
    },

    changeParam(state, param) {
      state.paramId = param;
    },

    changeBtn(state, btn) {
      state.whichBtn = btn;
    },

    loginUser(state, user) {
      state.user = user;
    },

    wichToSwitch(state, whichToShow) {
      state.whichToShow = whichToShow;
    },
    dogURL(state, dogURL) {
      state.bingoAdminURL = dogURL;
    },
  },
  actions: {
    changeLanguage(context, language) {
      context.commit("changeLanguage", language);
    },

    changeeditProfileDialog(context, editProfileDialog) {
      context.commit("changeeditProfileDialog", editProfileDialog);
    },

    changeParam(context, param) {
      context.commit("changeParam", param);
    },

    changeBtn(context, btn) {
      context.commit("changeBtn", btn);
    },

    loginUser(context, user) {
      context.commit("loginUser", user);
    },

    wichToSwitch(context, whichToShow) {
      context.commit("wichToSwitch", whichToShow);
    },
    dogURL(context, dogURL) {
      context.commit("dogURL", dogURL);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
