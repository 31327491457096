<template>
  <v-app>
    <navBar />
    <v-main id="body">
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer
      style="border-top: 1px solid #110f1a"
      app
      class="pl-0 pb-2 pt-2"
      id="footer"
      v-if="$store.state.user != ''"
    >
      <v-layout row wrap class="pa-2 pl-4 pr-1 text-center">
        <v-flex xs2>
          <v-btn
            id="btBtns"
            @click="
              $store.dispatch('changeBtn', 'home');
              $router
                .push({
                  name: 'home',
                })
                .catch((err) => {});
            "
            v-if="$store.state.whichBtn == 'home'"
            :ripple="false"
            text
            class="text-capitalize white--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
              background-color: #110f1a;
            "
          >
            <v-flex xs12>
              <v-icon class="white--text mb-1"> home </v-icon> <br />

              <span v-if="$store.state.language == 'English'">home</span>
              <span v-else>መነሻ</span>
            </v-flex>
          </v-btn>
          <v-btn
            @click="
              $store.dispatch('changeBtn', 'home');
              $router
                .push({
                  name: 'home',
                })
                .catch((err) => {});
            "
            id="btBtns"
            :ripple="false"
            v-else
            text
            class="text-capitalize grey--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
            "
          >
            <v-flex xs12>
              <v-icon class="grey--text text--darken-1 mb-1"> home </v-icon>
              <br />
              <span v-if="$store.state.language == 'English'">home</span>
              <span v-else>መነሻ</span>
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex xs2>
          <v-btn
            id="btBtns"
            @click="
              $store.dispatch('changeBtn', 'jobs');
              $router
                .push({
                  name: 'myjobs',
                })
                .catch((err) => {});
            "
            v-if="$store.state.whichBtn == 'jobs'"
            :ripple="false"
            text
            class="text-capitalize white--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
              background-color: #110f1a;
            "
          >
            <v-flex xs12>
              <v-icon class="white--text mb-1"> work </v-icon> <br />
              <span v-if="$store.state.language == 'English'">My Jobs</span>
              <span v-else>ሥራዎቼ</span>
            </v-flex>
          </v-btn>
          <v-btn
            @click="
              $store.dispatch('changeBtn', 'jobs');
              $router
                .push({
                  name: 'myjobs',
                })
                .catch((err) => {});
            "
            id="btBtns"
            :ripple="false"
            v-else
            text
            class="text-capitalize grey--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
            "
          >
            <v-flex xs12>
              <v-icon class="grey--text text--darken-1 mb-1"> work </v-icon>
              <br />
              <span v-if="$store.state.language == 'English'">My Jobs</span>
              <span v-else>ሥራዎቼ</span>
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex xs2>
          <v-btn
            id="btBtns"
            :ripple="false"
            @click="
              $store.dispatch('changeBtn', 'Careers');
              $router
                .push({
                  name: 'career',
                })
                .catch((err) => {});
            "
            v-if="$store.state.whichBtn == 'Careers'"
            text
            class="text-capitalize white--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
              background-color: #110f1a;
            "
          >
            <v-flex xs12>
              <v-icon class="white--text mb-1"> signal_cellular_alt</v-icon>
              <br />
              <span v-if="$store.state.language == 'English'">Careers</span>
              <span v-else>ሙያዎች</span>
            </v-flex>
          </v-btn>
          <v-btn
            @click="
              $store.dispatch('changeBtn', 'Careers');
              $router
                .push({
                  name: 'career',
                })
                .catch((err) => {});
            "
            id="btBtns"
            :ripple="false"
            v-else
            text
            class="text-capitalize grey--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
            "
          >
            <v-flex xs12>
              <v-icon class="grey--text text--darken-1 mb-1">
                signal_cellular_alt
              </v-icon>
              <br />
              <span v-if="$store.state.language == 'English'">Careers</span>
              <span v-else>ሙያዎች</span>
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex xs2>
          <v-btn
            id="btBtns"
            @click="
              $store.dispatch('changeBtn', 'notification');
              $router
                .push({
                  name: 'alert',
                })
                .catch((err) => {});
            "
            v-if="$store.state.whichBtn == 'notification'"
            :ripple="false"
            text
            class="text-capitalize white--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
              background-color: #110f1a;
            "
          >
            <v-flex xs12>
              <v-icon class="white--text mb-1"> notifications </v-icon> <br />

              <span v-if="$store.state.language == 'English'">Alerts</span>
              <span v-else>ማሳወቂያ</span>
            </v-flex>
          </v-btn>
          <v-btn
            @click="
              $store.dispatch('changeBtn', 'notification');
              $router
                .push({
                  name: 'alert',
                })
                .catch((err) => {});
            "
            id="btBtns"
            :ripple="false"
            v-else
            text
            class="text-capitalize grey--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
            "
          >
            <v-flex xs12>
              <v-icon class="grey--text text--darken-1 mb-1">
                notifications
              </v-icon>
              <br />

              <span v-if="$store.state.language == 'English'">Alerts</span>
              <span v-else>ማሳወቂያ</span>
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex xs2>
          <v-btn
            id="btBtns"
            :ripple="false"
            @click="
              $store.dispatch('changeBtn', 'contact');
              $router
                .push({
                  name: 'about',
                })
                .catch((err) => {});
            "
            v-if="$store.state.whichBtn == 'contact'"
            text
            class="text-capitalize white--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
              background-color: #110f1a;
            "
          >
            <v-flex xs12>
              <v-icon class="white--text mb-1"> info </v-icon> <br />

              <span v-if="$store.state.language == 'English'">About</span>
              <span v-else>ስለኛ</span>
            </v-flex>
          </v-btn>
          <v-btn
            @click="
              $store.dispatch('changeBtn', 'contact');
              $router
                .push({
                  name: 'about',
                })
                .catch((err) => {});
            "
            id="btBtns"
            :ripple="false"
            v-else
            text
            class="text-capitalize grey--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
            "
          >
            <v-flex xs12>
              <v-icon class="grey--text text--darken-1 mb-1"> info </v-icon>
              <br />

              <span v-if="$store.state.language == 'English'">About</span>
              <span v-else>ስለኛ</span>
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex xs2>
          <v-btn
            id="btBtns"
            :ripple="false"
            @click="
              $store.dispatch('changeBtn', 'profile');
              $store.dispatch('changeeditProfileDialog', true);
            "
            v-if="$store.state.whichBtn == 'profile'"
            text
            class="text-capitalize white--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
              background-color: #110f1a;
            "
          >
            <v-flex xs12>
              <v-icon class="white--text mb-1"> person </v-icon> <br />
              <span v-if="$store.state.language == 'English'">profile</span>
              <span v-else>መለያ</span>
            </v-flex>
          </v-btn>
          <v-btn
            @click="
              $store.dispatch('changeBtn', 'profile');
              $store.dispatch('changeeditProfileDialog', true);
            "
            id="btBtns"
            :ripple="false"
            v-else
            text
            class="text-capitalize grey--text pt-6 pb-7 mr-1"
            style="
              border-radius: 8px;
              padding-left: 3px;
              padding-right: 3px;
              font-size: 10px;
            "
          >
            <v-flex xs12>
              <v-icon class="grey--text text--darken-1 mb-1"> person </v-icon>
              <br />
              <span v-if="$store.state.language == 'English'">profile</span>
              <span v-else>መለያ</span>
            </v-flex>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import navBar from "./components/navBar.vue";

export default {
  components: {
    navBar,
  },
  data: () => ({
    whichBtn: "home",
    //
  }),

  async mounted() {
    if (window.Telegram.WebApp) {
      await window.Telegram.WebApp.setHeaderColor("#110f1a");
    }
  },
};
</script>
<style scoped>
#body {
  background-color: #110f1a;
}

#footer {
  background-color: #292732;
}
#btBtns:hover,
#btBtns:active,
#btBtns:focus,
#btBtns.v-btn--active {
  background-color: #110f1a !important;
  box-shadow: none;
}
#btBtns.v-btn--active {
  background-color: #110f1a !important;
}

#btBtns::before,
#btBtns::after {
  background-color: transparent !important;
}
</style>
