<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" v-if="!pageLoading" class="pl-1 pr-1">
          <profileHeader />

          <div style="margin-top: -28px">
            <h4 class="text-center white--text pb-3">
              You have posted {{ allPosts.length }}
              {{ allPosts.length == 1 ? " Job" : " Jobs" }}
            </h4>
            <br />

            <v-layout
              row
              wrap
              class="white--text"
              v-for="(post, i) in allPosts"
              :key="i"
            >
              <v-flex xs8 class="pl-2 pr-1">
                <span
                  ><strong>{{ post.title.replace("@@cont@@", ",") }}</strong>
                  &nbsp;&nbsp;|
                  <span style=""
                    >&nbsp;
                    <v-chip
                      @click="
                        showApplicantsDialog = true;
                        showApplicantsData = post;
                      "
                      style="background-color: #e96910; font-size: 11px"
                      class="white--text"
                      x-small
                    >
                      {{ post.getApplications.length }} Applied
                    </v-chip></span
                  ></span
                >
                <br />

                <span class="truncate-text grey--text">{{
                  post.detail_description
                }}</span>
              </v-flex>

              <v-flex xs4 class="pr-1 text-right pt-2">
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() <
                      Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                  "
                  class="pink--text"
                  >Expired</Span
                >

                <Span v-if="post.isrejected == 1" class="pink--text"
                  >Rejected</Span
                >
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 0
                  "
                  class="orange--text"
                  >Pending</Span
                >

                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 1
                  "
                  class="green--text"
                  >Active</Span
                >

                <v-menu transition="scale-transition" rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small text class="ml-1" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">more_vert</v-icon></v-btn
                    >
                  </template>
                  <v-card
                    style="background-color: #292732"
                    width="150px"
                    class="text-center pt-2 pb-2 pr-5"
                  >
                    <v-btn
                      @click="
                        detailDialog = true;
                        selectedDetail = post;
                      "
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="grey--text mr-1">visibility</v-icon> More
                    </v-btn>
                    <br />
                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="blue--text mr-1">edit</v-icon> Edit
                    </v-btn>
                    <br />

                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="red--text mr-1">delete</v-icon> delete
                    </v-btn>
                  </v-card>
                </v-menu>
              </v-flex>

              <v-flex xs12 class="mt-2 mb-2">
                <v-divider style="border-color: #292732"></v-divider>
              </v-flex>
            </v-layout>
          </div>

          <v-dialog
            transition="dialog-bottom-transition"
            v-model="showApplicantsDialog"
            fullscreen
            persistent
            v-if="showApplicantsData != ''"
          >
            <v-card style="background-color: #110f1a" class="white--text">
              <v-card-title>
                {{
                  $store.state.language == "English" ? "Job Detail" : "የሥራ ዝርዝር"
                }}
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="pink"
                  text
                  @click="showApplicantsDialog = false"
                >
                  {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
                </v-btn>
              </v-card-title>

              <v-card-text class="pb-6">
                <v-form ref="form" lazy-validation>
                  <v-layout row wrap class="pa-1 mt-1 white--text">
                    <v-flex
                      xs12
                      v-for="(job, i) in showApplicantsData.title.split(
                        '@@cont@@'
                      )"
                      :key="i"
                    >
                      <span v-if="i == 0"
                        ><strong>Company Name: </strong>
                        {{ showApplicantsData.company_name }}</span
                      >

                      <br v-if="i == 0" />
                      <span v-if="i == 0"
                        ><strong>Company Address: </strong>
                        {{ showApplicantsData.company_address }}</span
                      >
                      <br v-if="i == 0" />
                      <br v-if="i == 0" />
                      <span><strong>Title: </strong> {{ job }}</span>
                      <br />
                      <span
                        v-if="
                          showApplicantsData.sex.split('@@cont@@')[i] != '' &&
                          showApplicantsData.sex.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Applicants Needed: </strong>
                        {{ showApplicantsData.sex.split("@@cont@@")[i] }}</span
                      >
                      <br
                        v-if="
                          showApplicantsData.sex.split('@@cont@@')[i] != '' &&
                          showApplicantsData.sex.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          showApplicantsData.vacancies.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.vacancies.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Vacancies: </strong>
                        {{
                          showApplicantsData.vacancies.split("@@cont@@")[i]
                        }}</span
                      >

                      <br
                        v-if="
                          showApplicantsData.vacancies.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.vacancies.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          showApplicantsData.salary.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.salary.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Salary: </strong>
                        {{
                          showApplicantsData.salary.split("@@cont@@")[i]
                        }}</span
                      >

                      <br
                        v-if="
                          showApplicantsData.salary.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.salary.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          showApplicantsData.experience.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.experience.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Experience Level: </strong>
                        {{
                          showApplicantsData.experience.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          showApplicantsData.experience.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.experience.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          showApplicantsData.education.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.education.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Education Qualification: </strong>
                        {{
                          showApplicantsData.education.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          showApplicantsData.education.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.education.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          showApplicantsData.job_type.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.job_type.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Job Type: </strong>
                        {{
                          showApplicantsData.job_type.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          showApplicantsData.job_type.split('@@cont@@')[i] !=
                            '' &&
                          showApplicantsData.job_type.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />

                      <div
                        class="mt-4"
                        v-if="
                          i ==
                          showApplicantsData.title.split('@@cont@@').length - 1
                        "
                      >
                        <span
                          v-if="
                            new Date(
                              showApplicantsData.deadline_date
                            ).getTime() +
                              24 * 60 * 60 * 1000 >
                            Date.now()
                          "
                          ><strong>Deadline: </strong>
                          {{ getDeadlineApplied }}</span
                        >

                        <span v-else class="pink--text"
                          ><strong>Deadline: </strong> (
                          {{ getDeadlineApplied }}), Expired</span
                        >
                        <!-- 
                        <v-img
                          v-if="showApplicantsData.short_image != ''"
                          class="ml-0 mr-0 mt-2 mb-3"
                          width="100%"
                          height="150"
                          :src="showApplicantsData.short_image"
                          contain
                        ></v-img> -->

                        <span
                          style="white-space: pre-line"
                          class="truncate-text"
                          ><strong>Description: </strong>
                          {{ showApplicantsData.detail_description }}</span
                        >
                      </div>
                      <br />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>

              <v-card-text
                style="
                  border-top: 4px solid #4f3606;
                  border-top-left-radius: 30px;
                  border-top-right-radius: 30px;
                  color: white;
                "
                class="pt-5 pl-3 pr-3 pb-15"
              >
                <h2 class="text-center">
                  <span v-if="selectedApplicants.length <= 1">
                    {{
                      $store.state.language == "English"
                        ? "Applicants"
                        : "አመልካቾች"
                    }}
                  </span>

                  <v-btn
                    @click="acceptSelecetedAppts(1)"
                    v-if="selectedApplicants.length > 1"
                    text
                    small
                    outlined
                    style="border-color: blue"
                    class="green--text text-capitalize ml-3"
                  >
                    <span>Accept Selected</span>
                  </v-btn>
                  <v-btn
                    @click="acceptSelecetedAppts(2)"
                    v-if="selectedApplicants.length > 1"
                    text
                    small
                    outlined
                    style="border-color: blue"
                    class="pink--text text-capitalize ml-3"
                  >
                    <span>Reject Selected</span>
                  </v-btn>
                </h2>
                <h4
                  class="text-center mt-4"
                  v-if="showApplicantsData.getApplications.length == 0"
                >
                  There have been no applications for your job.
                </h4>
                <v-layout
                  row
                  wrap
                  class="white--text mt-3"
                  v-for="(applicant, i) in showApplicantsData.getApplications"
                  :key="i + 100"
                >
                  <v-flex xs2 class="pl-2">
                    <a>
                      <v-avatar size="40px" id="userAvt">
                        <v-img
                          height="70"
                          width="70"
                          contain
                          v-if="applicant.getEmployees[0].user_pic != ''"
                          alt="Avatar"
                          :src="applicant.getEmployees[0].user_pic"
                        ></v-img>
                        <v-icon v-else class="white--text">person</v-icon>
                      </v-avatar>
                    </a>
                  </v-flex>
                  <v-flex xs9 class="pt-1">
                    <span
                      class="text-capitalize"
                      style="margin-left: -6px"
                      v-if="applicant.getEmployees[0].first_name != ''"
                      ><strong>{{
                        applicant.getEmployees[0].first_name +
                        " " +
                        applicant.getEmployees[0].middle_name
                      }}</strong>
                      &nbsp;<v-icon
                        v-if="applicant.getEmployees[0].isverified == 1"
                        small
                        class="blue--text"
                        >verified</v-icon
                      >
                    </span>
                    <br v-if="applicant.getEmployees[0].first_name != ''" />
                    <span
                      style="
                        font-size: 11px;
                        margin-top: -2px;
                        margin-left: -6px;
                      "
                      v-if="applicant.getEmployees[0].study_field != ''"
                      class="studyText grey--text"
                      >{{ applicant.getEmployees[0].study_field }}</span
                    >

                    <span
                      style="
                        font-size: 11px;
                        margin-top: -2px;
                        margin-left: -6px;
                      "
                      v-if="applicant.getEmployees[0].phone_number != ''"
                      class="studyText grey--text"
                      >{{ applicant.getEmployees[0].phone_number }}</span
                    >
                  </v-flex>

                  <v-flex xs1 class="pt-4 pr-2">
                    <input
                      @click="
                        if (applicant.isaccepted == 0)
                          selectApplicant(applicant, i);
                      "
                      type="checkbox"
                    />
                  </v-flex>

                  <v-flex xs12 class="mt-2 pl-2 pr-2">
                    <span class="letterText white--text">{{
                      applicant.cover_letter
                    }}</span>
                  </v-flex>

                  <v-flex xs12 class="mb-5"> </v-flex>

                  <v-flex xs3 class="pl-2">
                    <v-btn
                      @click="
                        openCv = false;
                        seeDetailEmployeeDialog = true;
                        employeeDetailData = applicant;
                      "
                      text
                      x-small
                      class="orange--text text-capitalize"
                    >
                      <span>See more</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3 class="text-center">
                    <v-btn
                      @click="
                        contactEmployee(applicant.getEmployees[0].tg_username)
                      "
                      text
                      x-small
                      class="blue--text text-capitalize"
                    >
                      <span>Send Message</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3 class="text-right">
                    <v-btn
                      v-if="applicant.isaccepted == 0"
                      @click="acceptApplicant(applicant, 1, i)"
                      text
                      x-small
                      class="green--text text-capitalize"
                    >
                      <span>Accept</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3 class="pl-5 pr-2">
                    <v-btn
                      v-if="applicant.isaccepted == 0"
                      @click="acceptApplicant(applicant, 2, i)"
                      text
                      x-small
                      class="pink--text text-capitalize"
                    >
                      <span>Reject</span>
                    </v-btn>
                    <v-btn
                      v-if="applicant.isaccepted == 2"
                      text
                      x-small
                      class="pink--text text-capitalize"
                    >
                      <span>Rejected</span>
                    </v-btn>

                    <v-btn
                      v-if="applicant.isaccepted == 1"
                      text
                      x-small
                      class="green--text text-capitalize"
                    >
                      <span>Accepted</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 class="mt-2 mb-4">
                    <v-divider style="border-color: #292732"></v-divider>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog
            transition="dialog-bottom-transition"
            v-model="seeDetailEmployeeDialog"
            fullscreen
            persistent
            v-if="employeeDetailData != ''"
          >
            <v-card style="background-color: #292732" class="white--text">
              <v-card-title>
                {{
                  $store.state.language == "English"
                    ? "Employee Detail"
                    : "የተቀጣሪ ዝርዝር"
                }}
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="pink"
                  text
                  @click="
                    seeDetailEmployeeDialog = false;
                    openCv = false;
                  "
                >
                  {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-layout row wrap class="white--text pb-15">
                  <v-flex xs12 class="text-center mt-4">
                    <a>
                      <v-avatar size="80px" id="userAvt">
                        <v-img
                          height="80"
                          width="80"
                          contain
                          v-if="
                            employeeDetailData.getEmployees[0].user_pic != ''
                          "
                          alt="Avatar"
                          :src="employeeDetailData.getEmployees[0].user_pic"
                        ></v-img>
                        <v-icon large v-else class="white--text">person</v-icon>
                      </v-avatar>
                    </a>
                    <br />
                    <v-icon
                      v-if="employeeDetailData.getEmployees[0].isverified == 1"
                      small
                      class="blue--text"
                      >verified</v-icon
                    >
                  </v-flex>
                  <v-flex xs12 class="pl-2 pr-2">
                    <h3 class="mb-2 mt-3">Personal information</h3>

                    <span
                      ><strong>Full Name: </strong
                      >{{
                        employeeDetailData.getEmployees[0].first_name +
                        " " +
                        employeeDetailData.getEmployees[0].middle_name +
                        " " +
                        employeeDetailData.getEmployees[0].last_name
                      }}</span
                    >
                    <br />
                    <span
                      ><strong>Phone Number: </strong
                      >{{
                        employeeDetailData.getEmployees[0].phone_number
                      }}</span
                    >
                    <br />

                    <span
                      ><strong>Address: </strong
                      >{{ employeeDetailData.getEmployees[0].address }}</span
                    >
                    <br />

                    <v-divider
                      class="mt-2"
                      style="border-color: grey"
                    ></v-divider>
                    <br />
                    <h3 class="mb-2">Education and Experience</h3>

                    <span
                      ><strong>Educational level: </strong
                      >{{ employeeDetailData.getEmployees[0].education }}</span
                    >
                    <br />
                    <span
                      ><strong>Field of Study | Skills: </strong
                      >{{
                        employeeDetailData.getEmployees[0].study_field
                      }}</span
                    >
                    <br />
                    <span
                      ><strong>Profile Summary: </strong
                      >{{
                        employeeDetailData.getEmployees[0].cover_letter
                      }}</span
                    >
                    <br />
                    <br />
                    <span class="mt-3"
                      ><strong>Application Letter: </strong
                      >{{ employeeDetailData.cover_letter }}</span
                    >
                    <br />
                    <v-divider
                      class="mt-2"
                      style="border-color: grey"
                    ></v-divider>
                    <br />
                    <h3 class="mb-2">Portfolio Links and CV</h3>
                    <span
                      ><strong>Portfolio: </strong
                      >{{
                        employeeDetailData.getEmployees[0].link != ""
                          ? employeeDetailData.getEmployees[0].link
                          : employeeDetailData.linkedin
                      }}</span
                    >
                    <br />
                    <span
                      ><strong>CV or Resum: </strong>
                      <a
                        v-if="employeeDetailData.getEmployees[0].cv != ''"
                        @click="openCv = !openCv"
                        >View CV</a
                      >
                      <span v-else>Cv is not attached</span>
                    </span>
                    <br />
                    <v-divider
                      class="mt-2"
                      style="border-color: grey"
                    ></v-divider>
                  </v-flex>
                  <v-flex
                    class="pb-15"
                    xs12
                    v-if="
                      employeeDetailData.getEmployees[0].cv != '' &&
                      openCv == true
                    "
                  >
                    <!-- <h2 class="text-center mt-3" v-if="loadPdf">Loading...</h2> -->
                    <!-- <iframe
                      :src="employeeDetailData.getEmployees[0].cv"
                      width="100%"
                      height="600px"
                      style="border: none"
                      @load="onLoad"
                    ></iframe> -->

                    <div v-if="openCv == true">
                      <PDFViewer
                        class="pa-0 ma-0"
                        :source="employeeDetailData.getEmployees[0].cv"
                        style="height: 600px; width: 100%"
                        @load="handleDownload"
                      />
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog
            transition="dialog-bottom-transition"
            v-model="detailDialog"
            fullscreen
            persistent
            v-if="selectedDetail != ''"
          >
            <v-card style="background-color: #110f1a" class="white--text">
              <v-card-title>
                {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="pink"
                  text
                  @click="
                    detailDialog = false;
                    selectedDetail = '';
                  "
                >
                  {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
                </v-btn>
              </v-card-title>

              <v-card-text class="pb-15">
                <v-form ref="form" lazy-validation>
                  <v-layout row wrap class="pa-1 mt-1 white--text">
                    <v-flex
                      xs12
                      v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                      :key="i"
                    >
                      <span v-if="i == 0"
                        ><strong>Company Name: </strong>
                        {{ selectedDetail.company_name }}</span
                      >

                      <br v-if="i == 0" />
                      <span v-if="i == 0"
                        ><strong>Company Address: </strong>
                        {{ selectedDetail.company_address }}</span
                      >
                      <br v-if="i == 0" />
                      <br v-if="i == 0" />
                      <span><strong>Title: </strong> {{ job }}</span>
                      <br />
                      <span
                        v-if="
                          selectedDetail.sex.split('@@cont@@')[i] != '' &&
                          selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                        "
                        ><strong>Applicants Needed: </strong>
                        {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.sex.split('@@cont@@')[i] != '' &&
                          selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                          selectedDetail.vacancies.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Vacancies: </strong>
                        {{
                          selectedDetail.vacancies.split("@@cont@@")[i]
                        }}</span
                      >

                      <br
                        v-if="
                          selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                          selectedDetail.vacancies.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.salary.split('@@cont@@')[i] != '' &&
                          selectedDetail.salary.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Salary: </strong>
                        {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                      >

                      <br
                        v-if="
                          selectedDetail.salary.split('@@cont@@')[i] != '' &&
                          selectedDetail.salary.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            '' &&
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Experience Level: </strong>
                        {{
                          selectedDetail.experience.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            '' &&
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.education.split('@@cont@@')[i] != '' &&
                          selectedDetail.education.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Education Qualification: </strong>
                        {{
                          selectedDetail.education.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.education.split('@@cont@@')[i] != '' &&
                          selectedDetail.education.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                          selectedDetail.job_type.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Job Type: </strong>
                        {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                          selectedDetail.job_type.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />

                      <div
                        class="mt-4"
                        v-if="
                          i == selectedDetail.title.split('@@cont@@').length - 1
                        "
                      >
                        <span
                          v-if="
                            new Date(selectedDetail.deadline_date).getTime() +
                              24 * 60 * 60 * 1000 >
                            Date.now()
                          "
                          ><strong>Deadline: </strong> {{ getDeadline }}</span
                        >

                        <span v-else class="pink--text"
                          ><strong>Deadline: </strong> ( {{ getDeadline }}),
                          Expired</span
                        >

                        <v-img
                          v-if="selectedDetail.short_image != ''"
                          class="ml-0 mr-0 mt-2 mb-3"
                          width="100%"
                          height="150"
                          :src="selectedDetail.short_image"
                          contain
                        ></v-img>

                        <span style="white-space: pre-line"
                          ><strong>Description: </strong>
                          {{ selectedDetail.detail_description }}</span
                        >

                        <br />
                      </div>
                      <br />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog
            transition="dialog-bottom-transition"
            v-model="acceptRejectDialog"
            max-width="200px"
            persistent
          >
            <v-card style="background-color: #292732" class="white--text">
              <v-card-text>
                <div class="text-center pt-4" v-if="acceptRejectDialog">
                  <v-progress-circular
                    :size="50"
                    :width="2"
                    color="grey"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";
// import { getDocument } from "pdfjs-dist/build/pdf";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

export default {
  components: {
    profileHeader,
    PDFViewer,
  },
  data: () => ({
    pageLoading: false,
    showApplicantsDialog: false,
    allPosts: [],
    showApplicantsData: "",
    acceptRejectDialog: false,
    loadingCount: 0,
    selectedApplicants: [],
    seeDetailEmployeeDialog: false,
    employeeDetailData: "",
    openCv: false,
    loadPdf: false,
    detailDialog: false,
    selectedDetail: "",
  }),

  // mounted() {
  //   if (this.employeeDetailData != "")
  //     if (this.employeeDetailData.getEmployees[0].cv != "")
  //       this.loadPDF(this.employeeDetailData.getEmployees[0].cv);
  // },

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.deadline_date);
    },

    getDeadlineApplied() {
      return this.formatDate(this.showApplicantsData.deadline_date);
    },
  },

  methods: {
    // async loadPDF(url) {
    //   const loadingTask = getDocument(url);
    //   const pdf = await loadingTask.promise;
    //   const page = await pdf.getPage(1);

    //   const scale = 1.5;
    //   const viewport = page.getViewport({ scale });
    //   const canvas = this.$refs.pdfCanvas;
    //   const context = canvas.getContext("2d");

    //   canvas.width = viewport.width;
    //   canvas.height = viewport.height;

    //   const renderContext = {
    //     canvasContext: context,
    //     viewport: viewport,
    //   };
    //   await page.render(renderContext).promise;

    //   this.loadPdf = false;
    // },
    handleDownload() {
      this.loadPdf = false;
    },
    selectApplicant(selected, i) {
      if (
        this.selectedApplicants.indexOf(
          selected.application_id +
            "," +
            selected.getEmployees[0].tg_id +
            "," +
            i
        ) == -1
      ) {
        this.selectedApplicants.push(
          selected.application_id +
            "," +
            selected.getEmployees[0].tg_id +
            "," +
            i
        );
      } else {
        this.selectedApplicants.splice(
          this.selectedApplicants.indexOf(
            selected.application_id +
              "," +
              selected.getEmployees[0].tg_id +
              "," +
              i
          ),
          1
        );
      }
    },

    contactEmployee(tg) {
      window.location.href = "https://t.me/" + tg;
    },

    async acceptSelecetedAppts(status) {
      const acceptConfirm = confirm(
        status == 1
          ? "Are you sure you want to accept all the selected applicants?"
          : "Are you sure you want to reject all the selected applicants?"
      );
      if (acceptConfirm) {
        this.acceptRejectDialog = true;
        for (let i = 0; i < this.selectedApplicants.length; i++) {
          try {
            var result = await axios({
              method: "POST",
              url: this.$store.state.userURL,
              data: {
                query: `mutation{
                acceptRejectApplicant(
                  application_id:${parseInt(
                    this.selectedApplicants[i].split(",")[0].trim()
                  )}
                  tg_id:"${this.selectedApplicants[i].split(",")[1].trim()}"
                  title: "${this.showApplicantsData.title.replace(
                    "@@cont@@",
                    ","
                  )}"               
                  statusa: ${status}             

                  ){
                 statusMessage
              }
              }
              `,
              },
            });
            if (
              result.data.data.acceptRejectApplicant[0].statusMessage.toString() ==
              "true"
            ) {
              this.showApplicantsData.getApplications[
                parseInt(this.selectedApplicants[i].split(",")[2].trim())
              ].isaccepted = status;
            } else
              alert(
                status == 1
                  ? "Not accepted, Something went wrong"
                  : "Not rejected, Something went wrong"
              );
          } catch (err) {
            alert(err.message);
          }
        }
        await this.getAllData();
        this.acceptRejectDialog = false;
        this.selectedApplicants = [];
      }
    },
    async getAllData() {
      if (this.loadingCount == 0) {
        this.pageLoading = true;
        this.loadingCount++;
      }

      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllPosts(tg_id:"${this.$store.state.user.tg_id}"){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address
                language
                message_id

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
               
                  getApplications{
                        application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted

                        getEmployees{
                          user_id
                      tg_id
                      tg_username
                      first_name
                      middle_name
                      last_name
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      user_pic
                      isverified
                      cv
                      link
                        }

                }


           }
      }
      `,
          },
        });

        this.allPosts = result.data.data.getAllPosts;
      } catch (err) {
        alert(err.message);
      }
      this.pageLoading = false;
    },

    async acceptApplicant(applicant, status, index) {
      const acceptConfirm = confirm(
        status == 1
          ? "Are you sure you want to accept this applicant?"
          : "Are you sure you want to reject this applicant?"
      );
      if (acceptConfirm) {
        this.acceptRejectDialog = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `mutation{
                acceptRejectApplicant(
                  application_id:${parseInt(applicant.application_id)}
                  tg_id:"${applicant.getEmployees[0].tg_id}"
                  title: "${this.showApplicantsData.title.replace(
                    "@@cont@@",
                    ","
                  )}"               
                  statusa: ${status}             

                  ){
                 statusMessage
              }
              }
              `,
            },
          });
          if (
            result.data.data.acceptRejectApplicant[0].statusMessage.toString() ==
            "true"
          ) {
            await this.getAllData();
            this.showApplicantsData.getApplications[index].isaccepted = status;
          } else
            alert(
              status == 1
                ? "Not accepted, Something went wrong"
                : "Not rejected, Something went wrong"
            );
        } catch (err) {
          alert(err.message);
        }
        this.acceptRejectDialog = false;
      }
    },

    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },

  created() {
    this.getAllData();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

.studyText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 1; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
.letterText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

#userAvt {
  background-color: #e96910;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  accent-color: #0bb93f;
}
</style>
