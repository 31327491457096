<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" class="pl-1 pr-1" v-if="!pageLoading">
          <profileHeader />

          <div style="margin-top: -28px">
            <h4 class="text-center white--text pb-3">
              You have {{ allPosts.length }} Jobs to Approve
            </h4>
            <br />

            <v-layout
              row
              wrap
              class="white--text"
              v-for="(post, i) in allPosts"
              :key="i"
            >
              <v-flex xs8 class="pl-2 pr-1">
                <span
                  ><strong>{{ post.title.replace("@@cont@@", ",") }}</strong>
                  &nbsp;&nbsp;|
                  <span style=""
                    >&nbsp;
                    <v-chip
                      style="background-color: #e96910; font-size: 11px"
                      class="white--text"
                      x-small
                    >
                      {{ post.getApplications.length }} Applied
                    </v-chip></span
                  ></span
                >
                <br />

                <span class="truncate-text grey--text">{{
                  post.company_name
                }}</span>

                <a
                  @click="contactEmployee(post.getEmployer[0].tg_username)"
                  target="-b"
                >
                  <span class="truncate-text blue--text mt-1"
                    >@{{ post.getEmployer[0].tg_username }}</span
                  >
                </a>
              </v-flex>

              <v-flex xs4 class="pr-1 text-right pt-2">
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() <
                      Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                  "
                  class="pink--text"
                  >Expired</Span
                >

                <Span v-if="post.isrejected == 1" class="pink--text"
                  >Rejected</Span
                >
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 0
                  "
                  class="orange--text"
                  >Pending</Span
                >

                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 1
                  "
                  class="green--text"
                  >Active</Span
                >

                <v-menu transition="scale-transition" rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small text class="ml-1" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">more_vert</v-icon></v-btn
                    >
                  </template>
                  <v-card
                    style="background-color: #292732"
                    width="150px"
                    class="text-center pt-2 pb-2 pr-5"
                  >
                    <span v-if="1 == 1">
                      <v-btn
                        @click="approveJob(post)"
                        text
                        class="text-capitalize green--text ma-1"
                        v-if="
                          new Date(post.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                            Date.now() &&
                          post.isrejected == 0 &&
                          post.isapproved == 0
                        "
                      >
                        <v-icon class="green--text mr-1">check</v-icon> Approve
                      </v-btn>
                      <v-btn
                        @click="approveJob(post)"
                        text
                        class="text-capitalize green--text ma-1"
                        v-if="post.isrejected == 1 || post.isapproved == 1"
                      >
                        <v-icon class="green--text mr-1">check</v-icon> Repost
                      </v-btn>
                      <v-btn
                        @click="rejectJob(post)"
                        text
                        class="text-capitalize pink--text ma-1"
                        v-if="
                          new Date(post.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                            Date.now() &&
                          post.isrejected == 0 &&
                          post.isapproved == 0
                        "
                      >
                        <v-icon class="red--text mr-1">close</v-icon> Reject
                      </v-btn>
                    </span>

                    <v-divider
                      class="ml-3"
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0
                      "
                      style="border-color: grey"
                    ></v-divider>
                    <v-btn
                      @click="
                        selectedDetail = post;
                        detailDialog = true;
                      "
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="grey--text mr-1">visibility</v-icon> view
                    </v-btn>
                    <br />
                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="blue--text mr-1">edit</v-icon> Edit
                    </v-btn>
                    <br />

                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="red--text mr-1">delete</v-icon> delete
                    </v-btn>
                  </v-card>
                </v-menu>
              </v-flex>

              <v-flex xs12 class="mt-2 mb-2">
                <v-divider style="border-color: #292732"></v-divider>
              </v-flex>
            </v-layout>
          </div>

          <v-dialog
            transition="dialog-bottom-transition"
            v-model="detailDialog"
            fullscreen
            persistent
            v-if="selectedDetail != ''"
          >
            <v-card style="background-color: #110f1a" class="white--text">
              <v-card-title>
                {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="pink"
                  text
                  @click="
                    detailDialog = false;
                    selectedDetail = '';
                  "
                >
                  {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
                </v-btn>
              </v-card-title>

              <v-card-text class="pb-15">
                <v-form ref="form" lazy-validation>
                  <v-layout row wrap class="pa-1 mt-1 white--text">
                    <v-flex
                      xs12
                      v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                      :key="i"
                    >
                      <span v-if="i == 0"
                        ><strong>Company Name: </strong>
                        {{ selectedDetail.company_name }}</span
                      >

                      <br v-if="i == 0" />
                      <span v-if="i == 0"
                        ><strong>Company Address: </strong>
                        {{ selectedDetail.company_address }}</span
                      >
                      <br v-if="i == 0" />
                      <br v-if="i == 0" />
                      <span><strong>Title: </strong> {{ job }}</span>
                      <br />
                      <span
                        v-if="
                          selectedDetail.sex.split('@@cont@@')[i] != '' &&
                          selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                        "
                        ><strong>Applicants Needed: </strong>
                        {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.sex.split('@@cont@@')[i] != '' &&
                          selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                          selectedDetail.vacancies.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Vacancies: </strong>
                        {{
                          selectedDetail.vacancies.split("@@cont@@")[i]
                        }}</span
                      >

                      <br
                        v-if="
                          selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                          selectedDetail.vacancies.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.salary.split('@@cont@@')[i] != '' &&
                          selectedDetail.salary.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Salary: </strong>
                        {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                      >

                      <br
                        v-if="
                          selectedDetail.salary.split('@@cont@@')[i] != '' &&
                          selectedDetail.salary.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            '' &&
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Experience Level: </strong>
                        {{
                          selectedDetail.experience.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            '' &&
                          selectedDetail.experience.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.education.split('@@cont@@')[i] != '' &&
                          selectedDetail.education.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Education Qualification: </strong>
                        {{
                          selectedDetail.education.split("@@cont@@")[i]
                        }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.education.split('@@cont@@')[i] != '' &&
                          selectedDetail.education.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />
                      <span
                        v-if="
                          selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                          selectedDetail.job_type.split('@@cont@@')[i] !=
                            'undefined'
                        "
                        ><strong>Job Type: </strong>
                        {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                      >
                      <br
                        v-if="
                          selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                          selectedDetail.job_type.split('@@cont@@')[i] !=
                            'undefined'
                        "
                      />

                      <div
                        class="mt-4"
                        v-if="
                          i == selectedDetail.title.split('@@cont@@').length - 1
                        "
                      >
                        <span
                          v-if="
                            new Date(selectedDetail.deadline_date).getTime() +
                              24 * 60 * 60 * 1000 >
                            Date.now()
                          "
                          ><strong>Deadline: </strong> {{ getDeadline }}</span
                        >

                        <span v-else class="pink--text"
                          ><strong>Deadline: </strong> ( {{ getDeadline }}),
                          Expired</span
                        >

                        <v-img
                          v-if="selectedDetail.short_image != ''"
                          class="ml-0 mr-0 mt-2 mb-3"
                          width="100%"
                          height="150"
                          :src="selectedDetail.short_image"
                          contain
                        ></v-img>

                        <span style="white-space: pre-line"
                          ><strong>Description: </strong>
                          {{ selectedDetail.detail_description }}</span
                        >

                        <br />
                      </div>
                      <br />
                    </v-flex>
                  </v-layout>
                </v-form>
                <div class="mt-10 text-center">
                  <v-btn
                    @click="
                      detailDialog = false;
                      approveJob(selectedDetail);
                    "
                    class="pl-5 pr-5 mt-5 mb-15 white--text mr-7 text-capitalize"
                    text
                    small
                    style="
                      background-image: linear-gradient(#31ce5c, #242623);
                      border-radius: 10px;
                      font-size: 18px;
                    "
                    v-if="
                      new Date(selectedDetail.deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                        Date.now() &&
                      selectedDetail.isrejected == 0 &&
                      selectedDetail.isapproved == 0
                    "
                  >
                    Approve
                  </v-btn>

                  <v-btn
                    @click="
                      detailDialog = false;
                      rejectJob(selectedDetail);
                    "
                    class="pl-5 pr-5 mt-5 mb-15 white--text text-capitalize"
                    text
                    small
                    style="
                      background-image: linear-gradient(red, green);
                      border-radius: 10px;
                      font-size: 18px;
                    "
                    v-if="
                      new Date(selectedDetail.deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                        Date.now() &&
                      selectedDetail.isrejected == 0 &&
                      selectedDetail.isapproved == 0
                    "
                  >
                    Reject
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
    allPosts: [],
    detailDialog: false,
    selectedDetail: "",
  }),

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.deadline_date);
    },
  },

  methods: {
    contactEmployee(tg) {
      window.location.href = "https://t.me/" + tg;
    },
    async approveJob(job) {
      const approveConfirm = confirm(
        "Are you sure you want to approve this Job?"
      );
      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                approveJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                  title: "${job.title}"

                  detail_description: "${job.detail_description
                    .toString()
                    .trim()
                    .replace(/\n/g, "\\n")}"
                  short_image: "${job.short_image}"
                  detail_image: "${job.detail_image}"
                  company_address: "${job.company_address}"
                  deadline_date: "${job.deadline_date}"
                  apply_link: "${job.apply_link}"
                  sex: "${job.sex}"

                company_name: "${job.company_name}"
                 vacancies: "${job.vacancies}"
                 salary: "${job.salary}"
                 experience: "${job.experience}"
                 education: "${job.education}"
                 job_type: "${job.job_type}"
                 language: "${job.language}"



                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.approveJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else alert("Not approved, Something went wrong");
        } catch (err) {
          alert(err.message);
        }

        this.pageLoading = false;
      }
    },

    async rejectJob(job) {
      const approveConfirm = confirm(
        "Are you sure you want to reject this Job?"
      );
      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                rejectJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                  title: "${job.title.replace("@@cont@@", ",")}"            


                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.rejectJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else alert("Not rejected, Something went wrong, please try again");
        } catch (err) {
          alert(err.message);
        }

        this.pageLoading = false;
      }
    },
    async getAllDataToApprove() {
      this.pageLoading = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
          getAllPostsToApprove(tg_id:"${this.$store.state.user.tg_id}"){
                 post_id
                 tg_id
                 title
                  short_description
                  short_image
                  detail_description
                  detail_image
                  company_address
  
                 deadline_date
                  application_format
                  isdetail
                  isdirect
                 apply_link
                  isregistered
                  posted_date
  
                  iscv
                  islinkedin
                  iszerf
                 sex
                 age_above_bellow
                 isthesame
                 isapproved
                 isrejected
                language
                message_id
                company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
                 getEmployer{
                     user_id
                     tg_id
                     tg_username
                 }
                getApplications{
                        application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted

                        getEmployees{
                          user_id
                      tg_id
                      tg_username
                      first_name
                      middle_name
                      last_name
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                        }

                }
  
  
             }
        }
        `,
          },
        });

        this.allPosts = result.data.data.getAllPostsToApprove;
      } catch (err) {
        alert(err.message);
      }
      this.pageLoading = false;
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },
  created() {
    this.getAllDataToApprove();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
</style>
